import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  password: {
    inputProps: {
      label: "New password",
      name: "newPassword",
      required: true,
      type: "password",
    },
  },
  passwordRepeat: {
    inputProps: {
      type: "password",
      name: "repeatPassword",
      required: true,
      label: "Repeat new password",
    },
  },
});
