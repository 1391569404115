import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  linkDiv: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    textDecoration: "none",

    "&:hover > svg": {
      color: theme.palette.primary.main,
    },
    "&:hover > p": {
      color: theme.palette.primary.main,
      fontWeight: "600",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      flex: 1,
      width: "100%",
      bottom: 0,
      height: 2,
      background: theme.palette.primary.main,
      transform: "scaleX(0)",
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },

    transition: "transform 0.3s ease-in-out",
  },

  linkDivActive: {
    "svg, p": {
      color: theme.palette.primary.main,
      fontWeight: "600",
    },
    ":after": {
      transform: "scaleX(1)",
    },
  },

  link: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    transition: "color 0.3s ease-in-out",
  },

  icon: {
    transition: "color 0.3s ease-in-out",
    fontSize: 32,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.grey[600],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 32,
    maxWidth: 64,
    // alignSelf: "center",
  },
}));
