import { gql } from "@apollo/client";
import { ICheckpoint } from "../";
import { TAgeAppropriate } from "../../../utils";

export interface IInteractiveTour {
  id: string;
  duration: number | null;
  published: boolean;

  locale: {
    id: string;
    title: string | null;
  };

  created: string;
}

export interface IInteractiveToursData {
  allInteractiveTours: IInteractiveTour[];
}

export const ALL_INTERACTIVE_TOURS = gql`
  query allInteractiveTours {
    allInteractiveTours {
      id
      duration
      published
      locale(lang: "en-GB") {
        id
        title
      }
      created
    }
  }
`;

export interface IInteractiveTourDetails {
  id: string;
  duration: number | null;
  published: boolean;
  ageAppropriate: TAgeAppropriate | null;
  difficulty: {
    id: string;
  } | null;
  category: {
    id: string;
  } | null;
  allCheckpoints: ICheckpoint[];

  allLocales?: {
    id: string;
    title: string | null;
    description: string | null;
    introduction: string | null;
    conclusion: string | null;
    startingPoint: string | null;
    importantInfo: string | null;
    gallery:
      | {
          id: string;
          fileName: string;
          bucketPath: string;
          byteSize: number;
          mimeType: string;
          extension: string;
        }[]
      | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface IInteractiveTourDetailsData {
  oneInteractiveTourDetails: IInteractiveTourDetails;
}

export interface IInteractiveTourDetailsVars {
  id: number;
}

export const ONE_INTERACTIVE_TOUR_DETAILS = gql`
  query oneInteractiveTourDetails($id: ID!) {
    oneInteractiveTourDetails(id: $id) {
      id
      duration
      ageAppropriate
      difficulty {
        id
      }
      category {
        id
      }
      published
      allLocales {
        id
        title
        description
        introduction
        conclusion
        startingPoint
        importantInfo
        conclusion
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
        languageFlag {
          id
          isoLanguageCode
        }
      }

      allCheckpoints {
        id
        published
        created

        locale {
          id
          title
        }
      }
      created
    }
  }
`;
