import { FC, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useStyles } from "./ResetPasswordDialog.styles";
import { useSnackbar } from "notistack";
import { initialInputData } from "./ResetPasswordDialog.inputs";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../..";
import {
  IUpdatePersonPasswordData,
  IUpdatePersonPasswordVars,
  UPDATE_PERSON_PASSWORD,
} from "../../../../apollo/mutations";
import { useForm } from "../../../../utils";
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
};

type TProps = DialogProps & TDialogProps;

export const ResetPasswordDialog: FC<TProps> = (props) => {
  const { onClose, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [seePassword, setSeePassword] = useState(false);
  const [seePasswordNew, setSeePasswordNew] = useState(false);

  const handleToggleSeePassword = () => {
    setSeePassword(!seePassword);
  };
  const handleToggleSeePasswordNew = () => {
    setSeePasswordNew(!seePasswordNew);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { inputFields, resetFields, inputFieldNames, validateForm } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [updatePersonMutation, { loading }] = useMutation<
    IUpdatePersonPasswordData,
    IUpdatePersonPasswordVars
  >(UPDATE_PERSON_PASSWORD, {
    onCompleted: () => {
      enqueueSnackbar("Password changed!", {
        variant: "success",
      });
      handleClose();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleUpdatePassword = () => {
    if (validateForm(inputFieldNames)) {
      updatePersonMutation({
        variables: {
          data: {
            password: inputFields.currentPassword.values.unlocalised.value,
            passwordReset: inputFields.newPassword.values.unlocalised.value,
          },
        },
      });
    }
  };

  return (
    <Dialog onClose={handleClose} {...rest}>
      <DialogTitle>Change password</DialogTitle>

      <DialogContent className={classes.content}>
        <TextField
          {...inputFields.currentPassword.inputProps}
          fullWidth
          autoFocus
          type={seePassword ? "text" : "password"}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleSeePassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {seePassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputFields.newPassword.inputProps}
          type={seePasswordNew ? "text" : "password"}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleSeePasswordNew}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {seePasswordNew ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePassword}
        >
          Update
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loading} />
    </Dialog>
  );
};
