import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: theme.spacing(4),
  },

  //

  uploadAreaIcon: {
    // color: theme.palette.divider,
    fontSize: 86,
    zIndex: 2,
    opacity: 0.5,
  },
  hidden: {
    visibility: "hidden",
  },
  uploadButton: {
    width: "100%",
    height: 150,
    padding: 0,
    overflow: "hidden",
    position: "relative",
    transition: "all 0.3s ease-in-out",
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
  },
  uploadButtonActive: {
    width: "100%",
    height: 150,
    padding: 0,
    overflow: "hidden",
    position: "relative",
    border: "1px dashed",
    transition: "all 0.3s ease-in-out",
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4),
  },
  selectText: {
    marginRight: "auto",
  },
}));
