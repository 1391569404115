import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  ALL_LANGUAGE_FLAGS,
  CURRENT_USER,
  ICurrentUser,
  ICurrentUserData,
  ILanguageFlag,
  ILanguageFlagsData,
} from "./apollo/queries";
import { ErrorComponent, LoadingBackdrop } from "./components";
import { MainRouter } from "./routes/MainRouter";
import { ContextProvider } from "./utils";
import { ILogoutCmsData, LOGOUT } from "./apollo/mutations";

export const App = () => {
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [languageFlags, setLanguageFlags] = useState<ILanguageFlag[]>([]);
  const [user, setUser] = useState<ICurrentUser | undefined>(undefined);

  const [logoutMutation, { loading, client }] = useMutation<
    ILogoutCmsData,
    null
  >(LOGOUT, {
    onCompleted: async () => {
      await client.clearStore();
    },
  });

  const { error, data } = useQuery<ICurrentUserData>(CURRENT_USER, {
    onCompleted: async (data) => {
      if (data.currentUser) {
        if (
          !data.currentUser.activePersonRoleSubject?.id ||
          !data.currentUser.allPersonRoleSubjects?.length
        ) {
          setUser(undefined);
          //TODO: Logout?
          enqueueSnackbar(
            "You do not have the required permission to access this website!",
            { variant: "error" }
          );
          await logoutMutation();
          return;
        }
        setUser(data.currentUser);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: "error" });
    },
    fetchPolicy: "network-only",
  });

  const { enqueueSnackbar } = useSnackbar();

  const { error: errorFlags, data: dataFlags } = useQuery<
    ILanguageFlagsData,
    undefined
  >(ALL_LANGUAGE_FLAGS, {
    onCompleted: (data) => {
      setLanguageFlags(data.allLanguageFlags);
    },
  });

  useEffect(() => {
    if (data !== undefined && dataFlags?.allLanguageFlags?.length) {
      setReadyToLoad(true);
    }
  }, [data, dataFlags]);

  const contextValue = {
    user: user,
    setUser: setUser,
    languageFlags,
    setLanguageFlags,
  };

  if (error || errorFlags) {
    return <ErrorComponent />;
  }

  return (
    <ContextProvider.Provider value={contextValue}>
      {readyToLoad ? <MainRouter /> : null}
      <LoadingBackdrop loading={loading || !readyToLoad} />
    </ContextProvider.Provider>
  );
};
