import { useSnackbar } from "notistack";
import { useGlobalStyles } from "../../../utils/theme";
import { useStyles } from "./ReceptionPage.styles";
import {
  ContextProvider,
  displayCurrency,
  getDiscountedPrice,
  useForm,
} from "../../../utils";
import { initialInputData } from "./ReceptionPage.inputs";
import {
  DataHandlerComponent,
  LoadingBackdrop,
  PageLayout,
  PublishedShowcase,
  // PublishedShowcase,
} from "../../../components";
import { FC, SyntheticEvent, useContext, useState } from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Checkbox,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_PRODUCTS,
  IProduct,
  IProductsData,
  IProductsVars,
} from "../../../apollo/queries";
import {
  LockOpenOutlined as LockOpenOutlinedIcon,
  ShoppingBagOutlined as ShoppingBagOutlinedIcon,
} from "@mui/icons-material";
import { ReceptionConfirmationDialog } from "./components";
import {
  IUnlockContentData,
  IUnlockContentVars,
  UNLOCK_CONTENT,
} from "../../../apollo/mutations";

export const ReceptionPage: FC = () => {
  const { classes } = useStyles();
  const { user } = useContext(ContextProvider);
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [enableUnlockingScreen, setEnableUnlockingScreen] = useState(false);
  const { loading, data, error } = useQuery<IProductsData, IProductsVars>(
    ALL_PRODUCTS,
    {
      variables: {
        noFree: true,
      },
      fetchPolicy: "network-only",
    }
  );

  const {
    inputFields,
    inputFieldNames,
    setInputField,
    validateForm,
    didValuesChange,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const handleUnlockScreen = () => {
    setEnableUnlockingScreen(true);
  };

  const handleLockScreen = () => {
    resetFields(inputFieldNames);
    setEnableUnlockingScreen(false);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const [unlockContentMutation, { loading: loadingUnlockContentMutation }] =
    useMutation<IUnlockContentData, IUnlockContentVars>(UNLOCK_CONTENT, {
      onCompleted: (data) => {
        enqueueSnackbar(`Content unlocked!`, {
          variant: "success",
        });
        handleCloseConfirmDialog();
        handleLockScreen();
        resetFields(inputFieldNames);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Write cache
      // update: (cache, { data }) => {
      //   if (!data?.unlockContent) {
      //     enqueueSnackbar(
      //       "Failed to write cache data. Please refresh the page to see the recent changes.",
      //       { variant: "error" }
      //     );
      //     return;
      //   }
      //   const existingData: IReceiptsData | null =
      //     cache.readQuery({
      //       query: ALL_RECEIPTS,

      //     });
      //   const newItem: IReceipt = {
      //     ...data.unlockContent,
      //   };
      //   // console.log("EXISTING: ", existingData);
      //   // console.log("NEW: ", newItem);
      //   cache.writeQuery({
      //     query: ONE_INTERACTIVE_TOUR_DETAILS,
      //     variables: {
      //       id: +interactiveTourId,
      //     },
      //     data: {
      //       oneInteractiveTourDetails: {
      //         ...existingData?.oneInteractiveTourDetails,
      //         allCheckpoints: existingData?.oneInteractiveTourDetails
      //           ?.allCheckpoints
      //           ? [
      //               ...existingData.oneInteractiveTourDetails.allCheckpoints,
      //               newItem,
      //             ]
      //           : [newItem],
      //       },
      //     },
      //   });
      //   // console.log("CACHE: ", cache);
      // },
    });

  const handleUnlockContent = () => {
    if (validateForm(["productsToUnlock", "personId"], true)) {
      handleOpenConfirmDialog();
    }
  };

  const handleConfirmUnlock = () => {
    if (validateForm(["productsToUnlock", "personId"], true)) {
      unlockContentMutation({
        variables: {
          data: {
            personId: inputFields.personId.inputProps.value,
            productsId: inputFields.productsToUnlock.inputProps.value
              .split(",")
              .map((x: string) => +x),
            totalPrice: getTotal(true) as number,
            discount: inputFields.discount.inputProps.value
              ? inputFields.discount.inputProps.value
              : undefined,
          },
        },
      });
    }
  };

  const onChangeproductsToUnlock = (
    event: SyntheticEvent<Element, Event>,
    value: IProduct[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<IProduct> | undefined
  ) => {
    setInputField(
      "productsToUnlock",

      value.map((item) => item.id).toString()
    );
  };

  const getTotal = (raw?: boolean, discount?: string | undefined) => {
    let total = 0;
    inputFields.productsToUnlock.inputProps.value
      .split(",")
      .forEach((item: string) => {
        const product = data?.allProducts.find((x) => x.id === item);
        if (product) {
          total += product.price;
        }
      });
    if (raw) {
      if (discount) {
        return getDiscountedPrice(total, +discount);
      }
      return total;
    }
    if (discount) {
      return displayCurrency(
        +getDiscountedPrice(total, +discount),
        user?.activePersonRoleSubject?.subject?.businessModel?.currency?.isoCode
      );
    }
    return displayCurrency(
      total,
      user?.activePersonRoleSubject?.subject?.businessModel?.currency?.isoCode
    );
  };

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography variant="h6">Reception</Typography>
        </div>
        <div className={globalClasses.paperContainer}>
          {!enableUnlockingScreen ? (
            <div className={cx(classes.buttonDiv)}>
              <Button
                startIcon={<ShoppingBagOutlinedIcon />}
                variant="outlined"
                onClick={handleUnlockScreen}
                className={cx(classes.button)}
              >
                Unlock tours for user
              </Button>
            </div>
          ) : (
            <DataHandlerComponent
              hasData={Boolean(data?.allProducts)}
              error={Boolean(error)}
              loading={loading}
            >
              <TextField
                margin="normal"
                {...inputFields.personId.inputProps}
                autoFocus
                fullWidth
              />

              <Autocomplete
                id="connect-station"
                options={data?.allProducts || []}
                loading={loading}
                multiple
                disableCloseOnSelect
                autoComplete
                limitTags={50}
                getOptionLabel={(option) =>
                  option.classicTour?.locale?.title ||
                  option.interactiveTour?.locale?.title ||
                  `Unnamed tour ${option.id}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={onChangeproductsToUnlock}
                value={
                  data?.allProducts?.filter((x) =>
                    inputFields.productsToUnlock.values.unlocalised.value
                      .split(",")
                      .includes(x.id)
                  ) || []
                }
                renderOption={(props, option, { selected }) => {
                  const isClassicTour = Boolean(option?.classicTour?.id);

                  return (
                    <MenuItem
                      disabled={
                        isClassicTour
                          ? !option?.classicTour?.published
                          : !option?.interactiveTour?.published
                      }
                      {...props}
                    >
                      <ListItemText>
                        {option.classicTour?.locale?.title ||
                          option.interactiveTour?.locale?.title ||
                          `Unnamed tour ${option.id}`}
                      </ListItemText>
                      <ListItemIcon className={classes.priceContainer}>
                        <PublishedShowcase
                          className={cx(globalClasses.marginRight)}
                          published={
                            isClassicTour
                              ? option?.classicTour?.published || false
                              : option?.interactiveTour?.published || false
                          }
                        />
                        <Typography>
                          {displayCurrency(
                            option.price,
                            user?.activePersonRoleSubject?.subject
                              ?.businessModel?.currency?.isoCode
                          )}
                        </Typography>
                        <Checkbox
                          // icon={icon}
                          // checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...inputFields.productsToUnlock.inputProps}
                    {...params}
                    error={inputFields.productsToUnlock.inputProps.error}
                    label={inputFields.productsToUnlock.inputProps.label}
                    required={true}
                    margin="normal"
                    // InputProps={{
                    //   ...params.InputProps,
                    // }}
                  />
                )}
              />

              <TextField
                margin="normal"
                {...inputFields.discount.inputProps}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />

              {/* <InfoShowcase title="This only affects the price shown on billing when you unlock access to content via reception. It has no affect on in-app purchases." /> */}
            </DataHandlerComponent>
          )}
        </div>
        {enableUnlockingScreen ? (
          <div className={globalClasses.paperButtons}>
            <div className={globalClasses.paperButtonsLeft}>
              <Button onClick={handleLockScreen} variant="outlined">
                Cancel
              </Button>
            </div>
            <div className={globalClasses.paperButtonsRight}>
              <Button
                startIcon={<LockOpenOutlinedIcon />}
                onClick={handleUnlockContent}
                variant="contained"
                disabled={!didValuesChange(["personId", "productsToUnlock"])}
              >
                Continue
                {inputFields.productsToUnlock.inputProps.value
                  ? ` (Total: ${getTotal(
                      false,
                      inputFields.discount.inputProps.value
                    )})${
                      inputFields.discount.inputProps.value
                        ? ` (-${inputFields.discount.inputProps.value}%)`
                        : ""
                    }`
                  : ""}
              </Button>
            </div>
          </div>
        ) : null}
      </Paper>
      <LoadingBackdrop loading={loadingUnlockContentMutation} />
      <ReceptionConfirmationDialog
        callMutation={handleConfirmUnlock}
        fullWidth
        maxWidth="md"
        open={confirmDialogOpen}
        discount={inputFields.discount.inputProps.value}
        personId={inputFields.personId.inputProps.value}
        onClose={handleCloseConfirmDialog}
        price={getTotal(false, inputFields.discount.inputProps.value) as string}
        products={
          data?.allProducts?.filter((x) =>
            inputFields.productsToUnlock.values.unlocalised.value
              .split(",")
              .includes(x.id)
          ) || []
        }
      />
    </PageLayout>
  );
};
