import { gql } from "@apollo/client";

export interface IAdminRole {
  id: string;
  access: string;
  locale: {
    id: string;
    name: string;
    description: string | null;
  };
}

export interface IAdminRolesData {
  allAdminRoles: IAdminRole[];
}

export const ALL_ADMIN_ROLES = gql`
  query allAdminRoles {
    allAdminRoles {
      id
      access
      locale(lang: "en-GB") {
        id
        name
        description
      }
    }
  }
`;
