import { useSnackbar } from "notistack";
import { useGlobalStyles } from "../../../utils/theme";
// import { useStyles } from "./ReceiptListPage.styles";
import { DataHandlerComponent, PageLayout } from "../../../components";
import { FC, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { ALL_RECEIPTS, IReceiptsData } from "../../../apollo/queries";
import { ReceiptCancelationDialog, ReceiptItem } from "./components";
import { IDialogContext } from "../../../utils";

export const ReceiptListPage: FC = () => {
  // const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleOpenDialog = (id: string) => {
    setOpenDialog({
      id: id,
      open: true,
      type: undefined,
    });
  };

  const handelCloseDialog = () => {
    setOpenDialog({
      id: undefined,
      open: false,
      type: undefined,
    });
  };

  const { loading, data, error } = useQuery<IReceiptsData, null>(ALL_RECEIPTS, {
    fetchPolicy: "network-only", //TODO: Switch to cache
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography variant="h6">Receipts</Typography>
        </div>

        <div className={globalClasses.paperContainer}>
          <Typography color="textSecondary">
            List of generated receipts for purchases made at the reception.
          </Typography>
          <DataHandlerComponent
            hasData={Boolean(data?.allReceipts.length)}
            error={Boolean(error)}
            loading={loading}
          >
            {data?.allReceipts.length
              ? data.allReceipts.map((item) => {
                  return (
                    <ReceiptItem
                      data={item}
                      handleOpenDialog={handleOpenDialog}
                    />
                  );
                })
              : null}
          </DataHandlerComponent>
        </div>
      </Paper>
      <ReceiptCancelationDialog
        receiptId={openDialog.id}
        open={openDialog.open}
        onClose={handelCloseDialog}
      />
    </PageLayout>
  );
};
