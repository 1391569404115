import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useSnackbar } from "notistack";
import { useStyles } from "./ReceiptCancelationDialog.styles";
import { WarningOutlined as WarningOutlinedIcon } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import {
  CANCEL_RECEIPT,
  ICancelReceiptData,
  ICancelReceiptVars,
} from "../../../../../apollo/mutations";
import { LoadingBackdrop } from "../../../../../components";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  receiptId: string | undefined;
};

type TProps = DialogProps & TDialogProps;

export const ReceiptCancelationDialog: FC<TProps> = (props) => {
  const { receiptId, onClose, ...rest } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [cancelReceiptMutation, { loading }] = useMutation<
    ICancelReceiptData,
    ICancelReceiptVars
  >(CANCEL_RECEIPT, {
    onCompleted: (data) => {
      enqueueSnackbar(
        `Sucessfully cancelled receipt ${data.cancelReceipt.id}`,
        {
          variant: "success",
        }
      );
      onClose();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  const handleCancelReceipt = () => {
    if (receiptId) {
      cancelReceiptMutation({
        variables: {
          id: +receiptId,
        },
      });
    } else {
      enqueueSnackbar("Failed to find receipt ID! Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Dialog onClose={onClose} {...rest}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <>
            <Typography variant="subtitle1">
              Are you sure you want to cancel the receipt? This cannot be
              undone! The following things will happen:
            </Typography>
            <Divider className={classes.marginBottom} />
            <Typography>- The receipt will be marked as canceled</Typography>
            <Typography>
              - The customer will have their access revoked for the purchased
              products
            </Typography>
            <Typography>
              - The customer will be notified with email that his receipt was
              canceled and their access to the purchased products is revoked
            </Typography>
          </>
        </DialogContent>
        <DialogActions className={classes.buttonDiv}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelReceipt}
            disabled={loading}
            startIcon={<WarningOutlinedIcon />}
          >
            Confirm receipt -{receiptId}- cancelation
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  );
};
