import { createContext, Dispatch, SetStateAction } from "react";
import { ICurrentUser, ILanguageFlag } from "../apollo/queries";

export interface IContextProvider {
  user: ICurrentUser | undefined;
  setUser: Dispatch<SetStateAction<ICurrentUser | undefined>>;
  languageFlags: ILanguageFlag[];
  setLanguageFlags: Dispatch<SetStateAction<ILanguageFlag[]>>;
}

export const ContextProvider = createContext<IContextProvider>({
  user: undefined,
  setUser: () => {},
  languageFlags: [],
  setLanguageFlags: () => {},
});
