import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Classic tour Title",
      name: "title",
      placeholder: "A name of your Classic tour",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Title should be short and grab visitor attention enough to engage visitors into visiting this Classic tour. Do not include Classic tour numbering. It is added automatically.",
  },
  description: {
    localise: true,
    inputProps: {
      name: "description",
      label: "Classic tour Description",
      placeholder: "A short description of this Classic tour",
      required: true,
    },
    mainHelperText:
      "This short description should give visitors enough information to know what that particular Classic tour is all about.",
  },
  duration: {
    inputProps: {
      name: "duration",
      label: "Classic tour Duration",
      placeholder: "30",
      required: true,
    },
    regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
  },
  gallery: {
    inputProps: {
      label: "Gallery",
      name: "gallery",
    },
    rule: "media",
    required: true,
    localise: true,
    mainLocaleRequiredOnly: true,
  },
});
