import { gql } from "@apollo/client";

export interface IUpdateProduct {
  id: string;
  price: number;
}

export interface IUpdateProductData {
  updateProduct: IUpdateProduct;
}
export interface IUpdateProductVars {
  id: number;
  data: {
    price: number | undefined;
  };
}

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $data: ProductUpdateInput!) {
    updateProduct(id: $id, data: $data) {
      id
      price
    }
  }
`;
