import { FC } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";

interface IProps {
  title: string;
  buttonTitle?: string;
  description: string;
  onClose: any;
  open: boolean;
  mutation: any;
  refetch?: any; //TODO: Not used?
}

export const DeleteDialog: FC<IProps> = (props) => {
  const { onClose, open, title, description, mutation, buttonTitle } = props;

  const onDelete = (): void => {
    mutation?.();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onDelete}>
          {buttonTitle ? buttonTitle : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
