import { gql } from "@apollo/client";
export interface IDeleteStation {
  id: string;
}
export interface IDeleteStationData {
  deleteStation: IDeleteStation;
}
export interface IDeleteStationVars {
  id: number;
}
export const DELETE_STATION = gql`
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteStationLocale {
  id: string;
  localeId: string;
}

export interface IDeleteStationLocaleData {
  deleteStationLocale: IDeleteStationLocale;
}
export interface IDeleteStationLocaleVars {
  id: number;
}

export const DELETE_STATION_LOCALE = gql`
  mutation deleteStationLocale($id: ID!) {
    deleteStationLocale(id: $id) {
      id
      localeId
    }
  }
`;
