import { gql } from "@apollo/client";
import { IClassicTour, IInteractiveTour } from "../../queries";

export interface ICreateProduct {
  id: string;
  price: number;
  created: string;
  interactiveTour: IInteractiveTour | null;
  classicTour: IClassicTour | null;
}

export interface ICreateProductData {
  createProduct: ICreateProduct;
}
export interface ICreateProductVars {
  data: {
    price: number;
    interactiveTourId?: number;
    classicTourId?: number;
  } & (
    | { interactiveTourId: number; classicTourId?: never }
    | { classicTourId: number; interactiveTourId?: never }
  );
}

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductCreateInput!) {
    createProduct(data: $data) {
      id
      price
      created
      classicTour {
        id
        locale(lang: "en-GB") {
          title
        }
      }
      interactiveTour {
        id
        locale(lang: "en-GB") {
          title
        }
      }
    }
  }
`;
