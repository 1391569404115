import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    "& > span": {
      backgroundColor: theme.palette.background.default,
    },
    "& > span:nth-of-type(2n + 1)": {
      backgroundColor: theme.palette.secondary.lighter,
    },
  },
  skeleton: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));
