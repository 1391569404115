import { Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./InfoShowcase.styles";

interface IProps {
  title: string;
}

export const InfoShowcase: FC<IProps> = (props) => {
  const { title } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Typography color="textSecondary">{title}</Typography>
    </div>
  );
};
