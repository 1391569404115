import {
  ArrowDropDown as ArrowDropDownIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DragHandle as DragHandleIcon,
  EditOutlined as EditOutlinedIcon,
  LinkOffOutlined as LinkOffOutlinedIcon,
} from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { forwardRef, HTMLAttributes } from "react";
import { PublishedShowcase } from "../../..";
import { useGlobalStyles } from "../../../../utils/theme";
import { useStyles } from "./TreeItem.styles";

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  index?: number;
  data: {
    published: boolean;
    title: string;
  };
  onCollapse?(): void;
  handleEdit?(): void;
  handleDelete?(): void;
  deleteIsDisconnect?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      index,
      collapsed,
      onCollapse,
      // onRemove,
      style,
      data,
      wrapperRef,
      handleEdit,
      handleDelete,
      deleteIsDisconnect,

      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles({
      spacing: indentationWidth * depth,
    });

    const { classes: globalClasses } = useGlobalStyles();

    return (
      <li
        className={cx(
          classes.wrapper,
          disableInteraction ? classes.disableInteraction : null,
          disableSelection ? classes.disableSelection : null,
          clone ? classes.clone : null,
          ghost ? classes.ghost : null,
          indicator ? classes.indicator : null
        )}
        ref={wrapperRef}
        {...props}
      >
        <div className={classes.treeItem} ref={ref} style={style}>
          <IconButton {...handleProps}>
            <DragHandleIcon />
          </IconButton>
          {onCollapse && (
            <IconButton
              className={`${classes.collapse} 
            ${collapsed && classes.collapsed}
          `}
              size="small"
              onClick={onCollapse}
            >
              <ArrowDropDownIcon />
            </IconButton>
          )}
          <Typography className={classes.text}>{data.title}</Typography>

          {!clone ? (
            <>
              {handleEdit ? (
                <Button
                  size="small"
                  onClick={handleEdit}
                  startIcon={<EditOutlinedIcon />}
                  variant="outlined"
                  className={globalClasses.marginRight}
                >
                  Edit
                </Button>
              ) : null}
              {handleDelete ? (
                <Button
                  size="small"
                  onClick={handleDelete}
                  startIcon={
                    deleteIsDisconnect ? (
                      <LinkOffOutlinedIcon />
                    ) : (
                      <DeleteOutlinedIcon />
                    )
                  }
                  variant="outlined"
                  className={globalClasses.marginRight}
                >
                  {deleteIsDisconnect ? "Disconnect" : "Delete"}
                </Button>
              ) : null}
            </>
          ) : null}
          <PublishedShowcase published={data.published} />
          {index !== undefined ? (
            <Typography variant="caption" className={classes.index}>
              {index + 1}
            </Typography>
          ) : null}

          {clone && childCount && childCount > 1 ? (
            <span className={classes.count}>{childCount}</span>
          ) : null}
        </div>
      </li>
    );
  }
);
