import { FC, ReactNode } from "react";
import { useStyles } from "./PageLayout.styles";

interface IProps {
  children: ReactNode;
  justifyCenter?: boolean;
  displayFlex?: boolean;
}

export const PageLayout: FC<IProps> = (props) => {
  const { children, justifyCenter, displayFlex } = props;
  const { classes, cx } = useStyles();

  return (
    <main
      className={cx(
        classes.root,
        justifyCenter ? classes.justifyCenter : undefined,
        displayFlex ? classes.displayFlex : undefined
      )}
    >
      {children}
    </main>
  );
};
