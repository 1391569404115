import { FC, useContext } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
// import { useStyles } from "./GuidelinesPage.styles";
import { PageLayout } from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import { ContextProvider, ERoleAccess } from "../../../utils";

export const GuidelinesPage: FC = () => {
  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { user } = useContext(ContextProvider);

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div className={globalClasses.paperTitle}>
          <Typography variant="h6">Guidelines</Typography>
        </div>
        <List dense>
          {user?.activePersonRoleSubject?.role.access !==
          ERoleAccess.RECEPTION ? (
            <>
              <ListSubheader disableGutters>Classic tours</ListSubheader>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1KFXeG52oRPoRsBHWvnkE8wGp3ohiXyhyLhkJFLPnFZI"
                  >
                    Classic tour - Guidelines
                  </a>
                </ListItemText>
              </ListItem>
              <ListSubheader disableGutters>Interactive tours</ListSubheader>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/11RpPVC1uBYNBeUrBzzSD359r_By2ggUdbWbF6VShPfY"
                  >
                    How to create an Interactive tour
                  </a>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1RkYO-YDIydiXE247ZzJT-_oRlBQo4H_PIUxcclVMdA0"
                  >
                    Interactive tour - Guidelines
                  </a>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1p9tZigY-XAP6MXIyVgoNFWI8sQ0R2ZwVjUym22emNlQ"
                  >
                    Interactive tour - Types & themes
                  </a>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1TLkNAhctQ5QsXdIQSbJ9rABPJC-b5v0n-utl8dCdzkk"
                  >
                    Interactive tour - Challenge templates
                  </a>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
          {user?.activePersonRoleSubject?.role.access !== ERoleAccess.ADMIN &&
          user?.activePersonRoleSubject?.subject?.businessModel?.canSell ? (
            <>
              <ListSubheader disableGutters>Reception</ListSubheader>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1CUkbdZ47TEdIQFkFYil341Omql0ndSr5pB26rnlwcL8/edit?usp=sharing"
                  >
                    Reception, Receipt, Product, Refunds - Guidelines
                  </a>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
          {user?.activePersonRoleSubject?.role.access ===
            ERoleAccess.SUPER_ADMIN ||
          user?.activePersonRoleSubject?.role.access === ERoleAccess.OWNER ||
          user?.activePersonRoleSubject?.role.access === ERoleAccess.MANAGER ? (
            <>
              <ListSubheader disableGutters>About us</ListSubheader>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1b2FC_mwzZuvPIxE7whBAxScQ94af862TykfXJP7Y49k"
                  >
                    About us - Guidelines
                  </a>
                </ListItemText>
              </ListItem>
              <ListSubheader disableGutters>Administrators</ListSubheader>
              <ListItem>
                <ListItemText>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1_t-4XUfbVGzRdjrUOYDb5avyQmhKG390_w-UMa3ksEY/edit?usp=sharing"
                  >
                    Admin roles - Guidelines
                  </a>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
        </List>
      </Paper>
    </PageLayout>
  );
};
