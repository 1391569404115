import {
  Announcements,
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  buildTree,
  FlattenedItem,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  SensorContext,
  setProperty,
  sortableTreeKeyboardCoordinates,
  TreeItems,
} from "../../utils";
// import { useGlobalStyles } from "../../utils/theme";
import { useStyles } from "./SortableTreeList.styles";
import { CSS } from "@dnd-kit/utilities";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { useSnackbar } from "notistack";
import { DataHandlerComponent, SortableTreeItem } from "..";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  setOrderedList: Dispatch<SetStateAction<TreeItems>>;
  orderedList: TreeItems;
  loading: boolean;
  maxDepth?: number;
  handleDelete?: (id: string, callback: () => void) => void;
  handleEdit?: (id: string) => void;
  deleteIsDisconnect?: boolean;
  onReorder?: (
    beforeId: string | undefined,
    toId: string,
    nextId: string | undefined,
    parentId: string | null,
    callback?: () => void
  ) => void;
}

export const SortableTreeList: FC<Props> = ({
  collapsible,
  indicator = false,
  indentationWidth = 50,
  maxDepth = 0,
  loading,
  orderedList,
  setOrderedList,
  handleDelete,
  handleEdit,
  deleteIsDisconnect,
  onReorder,
}) => {
  const { classes } = useStyles();
  // const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(orderedList);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        //@ts-ignore
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      //@ts-ignore
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, orderedList]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
          maxDepth
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(
      sensorContext,
      indicator,
      indentationWidth,
      maxDepth
    )
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      // console.log("PROJECTED: ", projected);
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(orderedList))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      // const overTreeItem = clonedItems[overIndex];
      const activeTreeItem = clonedItems[activeIndex];

      // const overItemId = clonedItems[overIndex].id;

      if (projected.depth && activeTreeItem.children.length) {
        let exceeded = false;
        for (let i = 0; i < activeTreeItem.children.length; i++) {
          if (activeTreeItem.children[i].children.length) {
            exceeded = true;

            break;
          }
        }
        if (exceeded) {
          enqueueSnackbar(
            "Moving this nested station would exceed the maximum allowed depth of 2!",
            { variant: "warning" }
          );
          return null;
        }
      }
      if (projected.depth >= 2 && activeTreeItem.children.length) {
        enqueueSnackbar(
          "Moving this nested station would exceed the maximum allowed depth of 2!",
          { variant: "warning" }
        );
        return null;
      }

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      //TODO: Check if moving to same position
      if (
        activeIndex === overIndex &&
        activeTreeItem.depth === depth &&
        // eslint-disable-next-line
        activeTreeItem.parentId == parentId
      ) {
        return;
      }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      // console.log("BeforeId:", sortedItems[overIndex - 1]?.id);
      // console.log("ToId:", activeTreeItem.id);
      // console.log("NextId:", sortedItems[overIndex + 1]?.id);

      const newItems = buildTree(sortedItems);

      if (onReorder) {
        onReorder(
          sortedItems[overIndex - 1]?.id?.toString(),
          activeTreeItem.id.toString(),
          sortedItems[overIndex + 1]?.id.toString(),
          parentId ? parentId.toString() : null,
          () => {
            setOrderedList(newItems);
          }
        );
      } else {
        setOrderedList(newItems);
      }
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id: UniqueIdentifier) {
    setOrderedList((orderedList) => removeItem(orderedList, id));
    enqueueSnackbar("Removed connection", { variant: "info" });
  }

  function handleCollapse(id: UniqueIdentifier) {
    setOrderedList((orderedList) =>
      setProperty(orderedList, id, "collapsed", (value) => {
        return !value;
      })
    );
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(orderedList))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem?.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem?.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }

  const adjustTranslate: Modifier = ({ transform }) => {
    return {
      ...transform,
      y: transform.y - 25,
    };
  };

  return (
    <DataHandlerComponent
      loading={loading}
      error={false}
      hasData={Boolean(flattenedItems.length)}
    >
      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          <ul className={classes.sortableList}>
            {flattenedItems.length
              ? flattenedItems.map(
                  ({ id, children, data, collapsed, depth }, i) => (
                    <SortableTreeItem
                      key={id}
                      id={id}
                      index={i}
                      data={data}
                      depth={
                        id === activeId && projected ? projected.depth : depth
                      }
                      indentationWidth={indentationWidth}
                      indicator={indicator}
                      collapsed={Boolean(collapsed && children.length)}
                      onCollapse={
                        collapsible && children.length
                          ? () => handleCollapse(id)
                          : undefined
                      }
                      handleDelete={
                        handleDelete
                          ? () => {
                              handleDelete(id as string, () => {
                                handleRemove(id);
                              });
                            }
                          : undefined
                      }
                      handleEdit={
                        handleEdit ? () => handleEdit(id as string) : undefined
                      }
                      deleteIsDisconnect={deleteIsDisconnect}
                    />
                  )
                )
              : null}
            {createPortal(
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={indicator ? [adjustTranslate] : undefined}
              >
                {activeId && activeItem ? (
                  <SortableTreeItem
                    id={activeId}
                    depth={activeItem.depth}
                    clone
                    childCount={getChildCount(orderedList, activeId) + 1}
                    data={activeItem.data}
                    indentationWidth={indentationWidth}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
          </ul>
        </SortableContext>
      </DndContext>
    </DataHandlerComponent>
  );
};
