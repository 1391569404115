import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  buttonDiv: {
    justifyContent: "space-between",
  },
}));
