import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<
  { spacing: number },
  "treeItem" | "indicator" | "clone" | "ghost" | "text" | "count" | "index"
>()((theme, params, classes) => ({
  wrapper: {
    listStyle: "none",
    // boxSizing: "border-box",
    paddingLeft: params.spacing,
    marginBottom: -1,

    [`&.${classes.clone}`]: {
      listStyle: "none",
      display: "inline-block",
      pointerEvents: "none",
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(2),

      [`.${classes.treeItem}`]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        backgroundColor: theme.palette.primary.lighter,

        minWidth: 600,
        paddingRight: 24,
        borderRadius: 4,
        boxShadow: "0px 15px 15px 0 rgba(34, 33, 81, 0.1)",
      },
    },

    [`&.${classes.ghost}`]: {
      [`&.${classes.indicator}`]: {
        opacity: 1,
        position: "relative",
        zIndex: 1,
        marginBottom: -1,

        [`.${classes.treeItem}`]: {
          position: "relative",
          height: 8,
          borderColor: theme.palette.secondary.dark,
          backgroundColor: theme.palette.secondary.main,

          "&:before": {
            position: "absolute",
            left: -8,
            top: -4,
            display: "block",
            content: '""',
            width: 12,
            height: 12,
            borderRadius: "50%",
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: "#ffffff",
          },

          "> *": {
            /* Items are hidden using height and opacity to retain focus */
            opacity: 0,
            height: 0,
          },
        },
      },

      [`&:not(.${classes.indicator})`]: {
        opacity: 0.5,
      },

      [`.${classes.treeItem} > *`]: {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
  },

  treeItem: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    height: 64,
    opacity: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.secondary.lighter,
    border: `1px solid ${theme.palette.grey[200]}`,
  },

  text: {
    flexGrow: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  index: {
    margin: theme.spacing(1),
    width: 32,
    textAlign: "center",
  },

  count: {
    position: "absolute",
    top: -10,
    right: -10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#2389ff",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#fff",
  },

  disableInteraction: {
    pointerEvents: "none",
  },

  disableSelection: {
    [`.${classes.text}, .${classes.count} , .${classes.index}`]: {
      userSelect: "none",
      webkitUserSelect: "none",
      backgroundColor: "red !important",
      color: "green !important",
    },
  },

  collapse: {
    svg: {
      transition: "transform 250ms ease",
    },
  },

  collapsed: {
    svg: {
      transform: "rotate(-90deg)",
    },
  },

  disconnectButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  indicator: {},
  clone: {},
  ghost: {},
}));
