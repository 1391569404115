import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    minHeight: 300,
  },
  button: {
    height: 96,
    width: 226,
  },
  priceContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  centerTextAndIcon: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      marginLeft: theme.spacing(1),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));
