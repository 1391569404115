import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  preview: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  previewTitle: {
    color: theme.palette.common.white,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  audio: {
    width: "80%",
  },
}));
