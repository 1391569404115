import { Skeleton } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./TableSkeleton.styles";

interface IProps {
  height: number;
  repeatNum: number;
}

export const TableSkeleton: FC<IProps> = (props) => {
  const { height, repeatNum } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {Array.from(Array(repeatNum)).map((_, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={"100%"}
          height={height}
          className={classes.skeleton}
        />
      ))}
    </div>
  );
};
