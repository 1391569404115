import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  personId: {
    inputProps: {
      label: "User ID",
      name: "userId",
      required: true,
    },
    regexPattern: /^(?:[1-9][0-9]*)?$/,
    mainHelperText:
      "Please enter the ID of the existing user to unlock the content. The user can find their ID number on their profile screen after registering on the app or logging in.",
  },
  productsToUnlock: {
    inputProps: {
      label: "Products to unlock",
      name: "productsToUnlock",
      required: true,
    },
    mainHelperText: "Choose the products you want to unlock for this user.",
  },
  discount: {
    inputProps: {
      label: "Discount",
      name: "discount",
      required: false,
    },
    regexPattern: /^(?:[0-9]|[1-9]?[0-9]|100)?$/,
    mainHelperText: "Discount for all products.",
  },
});
