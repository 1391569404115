import { Dialog } from "@mui/material";
import { FC } from "react";
import { IMediaSelectDialogContext } from "../../../../utils";
import { MediaSelectorContent } from "../../MediaSelectorContent";
// import { useStyles } from "./MediaSelectorDialog.styles";

interface IProps {
  onClose: () => void;
  dialogContext: IMediaSelectDialogContext;
}

export const MediaSelectorDialog: FC<IProps> = (props) => {
  const { onClose, dialogContext } = props;
  // const { classes } = useStyles();

  return (
    <Dialog open={dialogContext.open} onClose={onClose} maxWidth="lg" fullWidth>
      <MediaSelectorContent
        onClose={onClose}
        isDialog
        dialogContext={dialogContext}
      />
    </Dialog>
  );
};
