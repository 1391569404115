import { gql } from "@apollo/client";
import { IOpeningHour } from "../";

export interface ISubjectDetails {
  id: string;
  published: boolean;
  email: string;
  phone: string;
  allOpeningHours: IOpeningHour[];
  header?: {
    id: string;
    fileName: string;
    bucketPath: string;
    byteSize: number;
    mimeType: string;
    extension: string;
  };

  allLocales?: {
    id: string;
    name: string;
    about: string;
    extraInfo: string;
    openingHoursInfo: string;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
}

export interface ISubjectDetailsData {
  oneSubjectDetails: ISubjectDetails;
}

export const ONE_SUBJECT_DETAILS = gql`
  query oneSubjectDetails {
    oneSubjectDetails {
      id
      email
      phone
      published
      allOpeningHours {
        id
        weekday
        startHour
        endHour
      }
      allLocales {
        id
        name
        about
        extraInfo
        openingHoursInfo
        languageFlag {
          id
          isoLanguageCode
        }
      }
      header {
        id
        fileName
        bucketPath
        byteSize
        mimeType
        extension
      }
    }
  }
`;
