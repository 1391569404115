import { FC } from "react";
import { useStyles } from "./ReceiptItem.styles";

import { IReceipt } from "../../../../../apollo/queries";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useGlobalStyles } from "../../../../../utils/theme";
import { ExpandMoreOutlined as ExpandMoreOutlinedIcon } from "@mui/icons-material";
import { ESource } from "../../../../../utils";

interface IProps {
  data: IReceipt;
  handleOpenDialog: (id: string) => void;
}

export const ReceiptItem: FC<IProps> = (props) => {
  const {
    created,
    details,
    externalSubjectId,
    id,
    personId,
    sellerEmail,
    sellerName,
    source,
    subjectAddress,
    subjectName,
    total,
    discount,
    discountTotal,
    expired,
    cancelledByEmail,
    cancelledByName,
  } = props.data;

  const { cx: cx1, classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();

  const openDialog = () => {
    props.handleOpenDialog(id);
  };

  return (
    <Accordion elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlinedIcon />}
        classes={{
          content: cx(
            globalClasses.justifySpaceBetween,
            globalClasses.fullWidth
          ),
        }}
      >
        <Typography width={"10%"}>
          <b>Receipt ID: {id}</b>
        </Typography>
        <Typography width={"35%"}>
          <b>Purchase date:</b>{" "}
          {format(new Date(created), "dd.MM.yyyy. HH:mm:ss")}
        </Typography>
        <Typography width={"35%"}>
          <b>Customer ID:</b> {personId || "- Account deleted -"}
        </Typography>
        <Typography
          className={cx1(
            classes.cancelledStatus,
            expired ? undefined : classes.hidden
          )}
          variant="overline"
        >
          CANCELLED
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <b>Customer ID:</b> {personId || "- Account deleted -"}
        </Typography>
        <Typography color="secondary" variant="overline">
          <b>Seller:</b>
        </Typography>
        <Divider />
        <Typography>
          <b>Name:</b> {sellerName}
        </Typography>
        <Typography>
          <b>Email:</b> {sellerEmail}
        </Typography>
        <Typography color="secondary" variant="overline">
          <b>Details:</b>
        </Typography>
        <Divider />
        <Typography className={classes.details}>
          <b>Products: </b>
          <br />
          {details}
        </Typography>
        <Typography>
          <b>Total:</b> {total}
        </Typography>
        {discount ? (
          <>
            <Divider />
            <Typography>
              <b>Discount:</b> {discount}%
            </Typography>
            <Typography>
              <b>Total with Discount:</b> {discountTotal}
            </Typography>
          </>
        ) : null}

        <Typography color="secondary" variant="overline">
          <b>Other:</b>
        </Typography>
        <Divider />
        <Typography>
          <b>Place of purchase:</b> {source}
        </Typography>
        <Typography>
          <b>External ID:</b> {externalSubjectId}
        </Typography>
        <Typography>
          <b>Name:</b> {subjectName}
        </Typography>
        <Typography>
          <b>Address:</b> {subjectAddress}
        </Typography>
        <Typography>
          <b>Receipt ID:</b> {id}
        </Typography>
        {expired ? (
          <>
            <Typography color="error" variant="overline">
              <b>Cancelled details:</b>
            </Typography>
            <Divider />
            <Typography>
              <b>Name:</b> {cancelledByName}
            </Typography>
            <Typography>
              <b>Email:</b> {cancelledByEmail}
            </Typography>
            <Typography>
              <b>Cancelled at:</b>{" "}
              {format(new Date(expired), "dd.MM.yyyy. HH:mm:ss")}
            </Typography>
          </>
        ) : null}
      </AccordionDetails>
      {!expired && source === ESource.RECEPTION ? (
        <AccordionActions>
          <Button onClick={openDialog} variant="outlined" color="secondary">
            Cancel receipt
          </Button>
        </AccordionActions>
      ) : null}
    </Accordion>
  );
};
