import { gql } from "@apollo/client";

export interface ICreateCheckpoint {
  id: string;
  published: boolean;
  locale: {
    id: string;
    title: string | null;
    directions: string | null;
    directionsHint: string | null;
    opening: string | null;
    challenge: string | null;
    challengeHint: string | null;
    solution: string | null;
    closing: string | null;
    facts: string | null;
  };
  created: string;
}

export interface ICreateCheckpointData {
  createCheckpoint: ICreateCheckpoint;
}
export interface ICreateCheckpointVars {
  data: {
    interactiveTourId: number;
  };
  dataLocale: {
    title: string | undefined;
    directions: string | undefined;
    directionsHint: string | undefined;
    directionsHintGallery: string[] | undefined;
    opening: string | undefined;
    challenge: string | undefined;
    challengeHint: string | undefined;
    solution: string | undefined;
    challengeHintGallery: string[] | undefined;
    closing: string | undefined;
    facts: string | undefined;
    factsGallery: string[] | undefined;
  };
}

export const CREATE_CHECKPOINT = gql`
  mutation createCheckpoint(
    $data: CheckpointCreateInput!
    $dataLocale: CheckpointLocaleCreateInitialInput!
  ) {
    createCheckpoint(data: $data, dataLocale: $dataLocale) {
      id
      published
      locale {
        id
        title
        directions
        directionsHint
        directionsHintGallery(localeSpecific: true) {
          id
        }
        opening
        challenge
        challengeHint
        challengeHintGallery(localeSpecific: true) {
          id
        }
        solution
        closing
        facts
        factsGallery(localeSpecific: true) {
          id
        }
      }

      created
    }
  }
`;
