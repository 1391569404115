import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  tabPanel: {
    padding: `${theme.spacing(2)} 0`,
    flex: 1,
  },
  buttonPrevious: {
    marginRight: "auto",
  },
}));
