import { gql } from "@apollo/client";

export interface IAdmin {
  id: string;
  person: {
    id: string;
    name: string;
    email: string;
  };
  role: {
    id: string;
    locale?: {
      id: string;
      name: string;
    };
  };
}

export interface IAdminData {
  oneAdministrator: IAdmin;
}

export interface IAdminsData {
  allAdministrators: IAdmin[];
}

export interface IAdminVars {
  id: number;
}

export const ONE_ADMIN = gql`
  query oneAdministrator($id: ID!) {
    oneAdministrator(id: $id) {
      id
      person {
        id
        name
        email
      }
      role {
        id
        locale {
          id
          name
        }
      }
    }
  }
`;

export const ALL_ADMINISTRATORS = gql`
  query allAdministrators {
    allAdministrators {
      id
      person {
        id
        name
        email
      }
      role {
        id
        locale {
          id
          name
        }
      }
    }
  }
`;
