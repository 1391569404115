import { createTypedDynamicFormInputs } from "../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    inputProps: {
      label: "Name",
      name: "name",
      placeholder: "John Doe",
      required: true,
    },
  },
  email: {
    rule: "email",
    inputProps: {
      label: "Email",
      name: "email",
      placeholder: "admin@example.com",
      required: true,
    },
  },
  role: {
    inputProps: {
      label: "Role",
      name: "role",
      placeholder: "Select role",
      required: true,
    },
  },
});
