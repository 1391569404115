import { gql } from "@apollo/client";

export interface IForgotPasswordData {
  forgotPassword: boolean;
}

export interface IForgotPasswordVars {
  data: {
    email: string;
  };
}

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data)
  }
`;
