import { gql } from "@apollo/client";

export interface IReceipt {
  id: string;
  personId: string | null;
  sellerName: string;
  sellerEmail: string;

  subjectName: string;
  subjectAddress: string;

  externalSubjectId: string | null;

  details: string;
  total: string;

  discount: number | null;
  discountTotal: string | null;
  source: string;

  cancelledByName: string;
  cancelledByEmail: string;

  created: string;
  expired: string;
}

export interface IReceiptsData {
  allReceipts: IReceipt[];
}

export const ALL_RECEIPTS = gql`
  query allReceipts {
    allReceipts {
      id
      personId
      sellerName
      sellerEmail
      subjectName
      subjectAddress
      externalSubjectId
      details
      total
      discount
      discountTotal
      source

      cancelledByName
      cancelledByEmail

      created
      expired
    }
  }
`;
