import { FC } from "react";
import { Button, Typography } from "@mui/material";
import { useStyles } from "./NotFoundPage.styles";
import { NavLink } from "react-router-dom";

export const NotFoundPage: FC = () => {
  const { classes } = useStyles();
  return (
    <main className={classes.root}>
      <Typography className={classes.title} variant="h1" paragraph>
        - Page not found -
      </Typography>
      <NavLink className={classes.link} to="/">
        <Button variant="contained">Home</Button>
      </NavLink>
    </main>
  );
};
