import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    backgroundColor: "#333333",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 2,
    top: 60,
    bottom: 0,
    left: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    boxShadow: "2px 0 2px rgba(0, 0, 0, 0.4)",
    overflowY: "auto",
  },
}));
