import { gql } from "@apollo/client";
import { IMedia } from "../media/media.query";

export interface IStation {
  id: string;
  published: boolean;

  locale: {
    id: string;
    title: string | null;
  };

  children?: IStation[];

  created: string;
}

export interface IStationsData {
  allStations: IStation[];
}

export const ALL_STATIONS = gql`
  query allStations {
    allStations {
      id
      published
      locale {
        id
        title
      }
      created
    }
  }
`;

export interface IStationDetails {
  id: string;
  published: boolean;
  textCode: string | null;

  allLocales?: {
    id: string;
    title: string | null;
    gallery: IMedia[] | null;
    description: string | null;
    audioGuideText: string | null;
    audioGuide: IMedia | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface IStationDetailsData {
  oneStationDetails: IStationDetails;
}

export interface IStationDetailsVars {
  id: number;
}

export const ONE_STATION_DETAILS = gql`
  query oneStationDetails($id: ID!) {
    oneStationDetails(id: $id) {
      id
      published
      textCode
      allLocales {
        id
        title
        description
        audioGuideText
        audioGuide {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
        languageFlag {
          id
          isoLanguageCode
        }
      }
      created
    }
  }
`;
