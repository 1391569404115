import { gql } from "@apollo/client";

export interface IUnlockContent {
  id: string;
}

export interface IUnlockContentData {
  unlockContent: IUnlockContent;
}
export interface IUnlockContentVars {
  data: {
    personId: number;
    productsId: number[];
    totalPrice: number;
    discount: number | undefined;
  };
}

export const UNLOCK_CONTENT = gql`
  mutation unlockContent($data: PersonPurchaseCreateInput!) {
    unlockContent(data: $data) {
      id
    }
  }
`;
