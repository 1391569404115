import { gql } from "@apollo/client";
import { IMedia } from "../../queries";

//*-------Update other
// export interface IUpdateCheckpointOther {
//   id: string;
// }

// export interface IUpdateCheckpointOtherData {
//   updateCheckpointOther: IUpdateCheckpointOther;
// }
// export interface IUpdateCheckpointOtherVars {
//   id: number;
//   data: {
//     // gallery: { id: number }[];
//   };
// }

// export const UPDATE_CHECKPOINT_OTHER = gql`
//   mutation updateCheckpointOther($id: ID!, $data: CheckpointOtherUpdateInput!) {
//     updateCheckpointOther(id: $id, data: $data) {
//       id
//     }
//   }
// `;

//*-------Update locale

export interface IUpdateCheckpointLocale {
  id: string;
  title: string | null;
  directions: string | null;
  directionsHint: string | null;
  opening: string | null;
  challenge: string | null;
  challengeHint: string | null;
  solution: string | null;
  closing: string | null;
  facts: string | null;
}

export interface IUpdateCheckpointLocaleData {
  updateCheckpointLocale: IUpdateCheckpointLocale;
}
export interface IUpdateCheckpointLocaleVars {
  id: number;
  data: {
    title: string | undefined | null;
    directions: string | undefined | null;
    directionsHint: string | undefined | null;
    opening: string | undefined | null;
    challenge: string | undefined | null;
    challengeHint: string | undefined | null;
    solution: string | undefined | null;
    closing: string | undefined | null;
    facts: string | undefined | null;
    factsGallery: string[] | undefined | null;
    challengeHintGallery: string[] | undefined | null;
    directionsHintGallery: string[] | undefined | null;
  };
}

export const UPDATE_CHECKPOINT_LOCALE = gql`
  mutation updateCheckpointLocale(
    $id: ID!
    $data: CheckpointLocaleUpdateInput!
  ) {
    updateCheckpointLocale(id: $id, data: $data) {
      id
      title
      directions
      directionsHint
      directionsHintGallery(localeSpecific: true) {
        id
      }
      opening
      challenge
      challengeHint
      challengeHintGallery(localeSpecific: true) {
        id
      }
      solution
      closing
      facts
      factsGallery(localeSpecific: true) {
        id
      }
    }
  }
`;

//*-------Create

export interface ICreateCheckpointLocale {
  id: string;
  title: string | null;
  directions: string | null;
  directionsHint: string | null;
  opening: string | null;
  challenge: string | null;
  challengeHint: string | null;
  challengeHintGallery: IMedia[];
  directionsHintGallery: IMedia[];
  factsGallery: IMedia[];
  solution: string | null;
  closing: string | null;
  facts: string | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateCheckpointLocaleData {
  createCheckpointLocale: ICreateCheckpointLocale;
}
export interface ICreateCheckpointLocaleVars {
  checkpointId: number;
  data: {
    title: string | undefined;
    directions: string | undefined;
    directionsHint: string | undefined;
    opening: string | undefined;
    challenge: string | undefined;
    challengeHint: string | undefined;
    solution: string | undefined;
    closing: string | undefined;
    facts: string | undefined;
    factsGallery: string[] | undefined;
    challengeHintGallery: string[] | undefined;
    directionsHintGallery: string[] | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_CHECKPOINT_LOCALE = gql`
  mutation createCheckpointLocale(
    $checkpointId: ID!
    $data: CheckpointLocaleCreateInput!
  ) {
    createCheckpointLocale(checkpointId: $checkpointId, data: $data) {
      id
      title
      directions
      directionsHint
      factsGallery(localeSpecific: true) {
        id
      }
      directionsHintGallery(localeSpecific: true) {
        id
      }
      challengeHintGallery(localeSpecific: true) {
        id
      }
      opening
      challenge
      challengeHint
      solution
      closing
      facts
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ UPDATE PUBLISHED

export interface IUpdateCheckpointPublished {
  id: string;
  published: boolean;
}

export interface IUpdateCheckpointPublishedData {
  updateCheckpointPublished: IUpdateCheckpointPublished | null;
}
export interface IUpdateCheckpointPublishedVars {
  id: number;
  publish: boolean;
  checkOnly: boolean;
}

export const UPDATE_CHECKPOINT_PUBLISHED = gql`
  mutation updateCheckpointPublished(
    $id: ID!
    $checkOnly: Boolean!
    $publish: Boolean!
  ) {
    updateCheckpointPublished(
      id: $id
      checkOnly: $checkOnly
      publish: $publish
    ) {
      id
      published
    }
  }
`;

//*------ UPDATE REORDER

export interface IUpdateCheckpointOrder {
  id: string;
  order: boolean;
}

export interface IUpdateCheckpointOrderData {
  updateCheckpointOrder: IUpdateCheckpointOrder;
}
export interface IUpdateCheckpointOrderVars {
  beforeId: number | undefined;
  toId: number;
  nextId: number | undefined;
}

export const UPDATE_CHECKPOINT_ORDER = gql`
  mutation updateCheckpointOrder($beforeId: ID, $toId: ID!, $nextId: ID) {
    updateCheckpointOrder(beforeId: $beforeId, nextId: $nextId, toId: $toId) {
      id
      order
    }
  }
`;
