import { ChangeEvent, FC, KeyboardEvent, useContext, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./LoginPage.styles";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ContextProvider, useForm } from "../../../utils";
import { ILoginCmsData, ILoginCmsVars, LOGIN } from "../../../apollo/mutations";
import { initialInputData } from "./LoginPage.inputs";
import { Link } from "react-router-dom";
import { LoadingBackdrop } from "../../../components";
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";

export const LoginPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const { setUser } = useContext(ContextProvider);
  const [seePassword, setSeePassword] = useState(false);

  const handleToggleSeePassword = () => {
    setSeePassword(!seePassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { inputFields, inputFieldNames, setInputField, validateForm } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("remember", event.target.checked ? "True" : "False");
  };

  const handleEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginMutation();
    }
  };

  const [loginMutation, { loading: loadingLoginMutation }] = useMutation<
    ILoginCmsData,
    ILoginCmsVars
  >(LOGIN, {
    onCompleted: (data) => {
      enqueueSnackbar("Uspješna prijava!", {
        variant: "success",
      });
      setUser(data.loginCms);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    variables: {
      data: {
        email: inputFields.email.inputProps.value,
        password: inputFields.password.inputProps.value,
        remember: inputFields.remember.inputProps.value === "True",
      },
    },
    fetchPolicy: "network-only",
  });

  const handleLoginMutation = () => {
    if (validateForm(inputFieldNames)) {
      loginMutation();
    }
  };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography component="h1" variant="h5" paragraph>
            Welcome
          </Typography>
          <Typography paragraph>
            Please, enter your login credentials.
          </Typography>
        </div>
        <TextField
          {...inputFields.email.inputProps}
          autoFocus
          margin="normal"
          autoComplete="username"
          fullWidth
        />
        <TextField
          {...inputFields.password.inputProps}
          fullWidth
          margin="normal"
          type={seePassword ? "text" : "password"}
          autoComplete="current-password"
          onKeyDown={handleEnterKey}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleSeePassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {seePassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={cx(classes.checkboxDiv)}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputFields.remember.inputProps.value === "True"}
                  onChange={handleCheckboxChange}
                />
              }
              label={inputFields.remember.inputProps.label}
            />
          </FormGroup>
          <MuiLink component={Link} to="/forgot-password">
            Forgot password?
          </MuiLink>
        </div>

        <Button
          className={cx(classes.button)}
          variant="contained"
          fullWidth
          onClick={handleLoginMutation}
        >
          Log in
        </Button>
      </Paper>
      <LoadingBackdrop loading={loadingLoginMutation} />
    </main>
  );
};
