import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  email: {
    rule: "email",
    inputProps: {
      type: "email",
      required: true,
      label: "Email",
      name: "email",
    },
  },
});
