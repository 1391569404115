import { gql } from "@apollo/client";

export interface IValidateAccountData {
  validateAccount: boolean;
}

export interface IValidateAccountVars {
  token: string;
}

export const VALIDATE_ACCOUNT = gql`
  mutation validateAccount($token: NonEmptyString!) {
    validateAccount(token: $token)
  }
`;
