import { gql } from "@apollo/client";

export interface ICreateOpeningHour {
  id: string;
  weekday: number;
  startHour: string;
  endHour: string;
}

export interface ICreateOpeningHourData {
  createOpeningHour: ICreateOpeningHour;
}
export interface ICreateOpeningHourVars {
  data: {
    weekday: number;
    startHour: string;
    endHour: string;
  };
}

export const CREATE_OPENING_HOUR = gql`
  mutation createOpeningHour($data: OpeningHourCreateInput!) {
    createOpeningHour(data: $data) {
      id
      weekday
      startHour
      endHour
    }
  }
`;
