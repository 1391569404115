import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useStyles } from "./MediaSelectorContent.styles";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingBackdrop } from "../loadingBackdrop/LoadingBackdrop";
import { PublishOutlined as PublishOutlinedIcon } from "@mui/icons-material";
import {
  CREATE_MEDIA,
  DELETE_MEDIA,
  ICreateMediaData,
  ICreateMediaVars,
  IDeleteMediaData,
  IDeleteMediaVars,
} from "../../apollo/mutations";
import {
  ALL_MEDIAS,
  IMedia,
  IMediasData,
  IMediasVars,
  IMediaWithoutCount,
} from "../../apollo/queries";
import { DataHandlerComponent } from "../dataHandlerComponent/DataHandlerComponent";
import { MediaItem, MediaPreviewDialog } from "./components";
import {
  ALLOWED_AUDIO_TYPES,
  ALLOWED_IMAGE_TYPES,
  ALLOWED_MEDIA_TYPES,
  IMediaDialogContext,
  IMediaSelectDialogContext,
  MAX_AUDIO_UPLOAD_SIZE,
  MAX_IMAGE_UPLOAD_SIZE,
} from "../../utils";
import { DeleteDialog } from "../";
import byteSize from "byte-size";
import { useGlobalStyles } from "../../utils/theme";

interface IProps {
  isDialog?: boolean;
  dialogContext?: IMediaSelectDialogContext;
  onClose?: () => void;
}

interface IPropsPaperContainer {
  children: ReactNode;
}

const PaperContainer: FC<IPropsPaperContainer> = (props) => {
  const { classes: globalClasses } = useGlobalStyles();

  return <div className={globalClasses.paperContainer}>{props.children}</div>;
};

const PaperTitle: FC<IPropsPaperContainer> = (props) => {
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <div className={globalClasses.paperTitle}>
      <Typography variant="h5" component="h1">
        Media
      </Typography>
    </div>
  );
};

export const MediaSelectorContent: FC<IProps> = (props) => {
  const { dialogContext, isDialog, onClose } = props;

  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMedia, setSelectedMedia] = useState<IMediaWithoutCount[]>([]);

  const [onDragEnterToggle, setOnDragEnterToggle] = useState(false);
  const [openDialog, setOpenDialog] = useState<IMediaDialogContext>({
    open: false,
    item: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({ ...prevState, open: false }));
  };

  const { data, loading, error } = useQuery<IMediasData, IMediasVars>(
    ALL_MEDIAS,
    {
      variables: {
        type: dialogContext?.type,
      },
    }
  );

  const [createMediaMutation, { loading: loadingCreateMutation }] = useMutation<
    ICreateMediaData,
    ICreateMediaVars
  >(CREATE_MEDIA, {
    onCompleted: (data) => {
      // console.log(data);
      enqueueSnackbar(`Media uploaded!`, {
        variant: "success",
      });
    },
    onError: (err) => {
      // console.warn("Error: ", { err });
      enqueueSnackbar(`Failed to upload media: ${err.message}`, {
        variant: "error",
      });
    },
    update: (cache, { data }) => {
      if (!data?.createMedia) {
        enqueueSnackbar("Failed to update cache. Please contact support.", {
          variant: "error",
        });
        return;
      }

      const newMediaData: IMedia = {
        ...data.createMedia,
        // _count: {
        //   StationMedia: 0,
        //   TourMedia: 0,
        // },
      };

      //Specific media type
      if (dialogContext?.type) {
        const existingListData: IMediasData | null = cache.readQuery({
          query: ALL_MEDIAS,
          variables: {
            type: dialogContext?.type,
          },
        });

        cache.writeQuery({
          query: ALL_MEDIAS,
          variables: {
            type: dialogContext.type,
          },
          data: {
            allMedias: existingListData?.allMedias
              ? [newMediaData, ...existingListData.allMedias]
              : [newMediaData],
          },
        });
      }
      const existingListData: IMediasData | null = cache.readQuery({
        query: ALL_MEDIAS,
      });
      cache.writeQuery({
        query: ALL_MEDIAS,
        data: {
          allMedias: existingListData?.allMedias
            ? [newMediaData, ...existingListData.allMedias]
            : [newMediaData],
        },
      });
    },
  });

  const [deleteMediaMutation, { loading: loadingDeleteMutation }] = useMutation<
    IDeleteMediaData,
    IDeleteMediaVars
  >(DELETE_MEDIA, {
    onCompleted: (data) => {
      enqueueSnackbar(`Media deleted: ${data.deleteMedia.fileName}!`, {
        variant: "success",
      });
      handleCloseDialog();
    },
    onError: (err) => {
      enqueueSnackbar(`Error while deleting media: ${err.message}`, {
        variant: "error",
      });
    },
  });

  const validateFiles = (file: File) => {
    const allowedTypes = dialogContext?.type
      ? dialogContext.type === "image"
        ? ALLOWED_IMAGE_TYPES
        : ALLOWED_AUDIO_TYPES
      : ALLOWED_MEDIA_TYPES;
    const maxSize = dialogContext?.type
      ? dialogContext.type === "image"
        ? MAX_IMAGE_UPLOAD_SIZE
        : MAX_AUDIO_UPLOAD_SIZE
      : MAX_AUDIO_UPLOAD_SIZE;
    if ((allowedTypes as string[]).indexOf(file.type) === -1) {
      enqueueSnackbar(`Wrong media format: ${file.type || "Unknown format"}`, {
        variant: "error",
      });
      return false;
    }
    if (file.size > maxSize) {
      enqueueSnackbar(
        `Media too big: ${byteSize(file.size)}! Must be less than ${byteSize(
          maxSize
        )}`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };

  const handleUpload = async (file: File) => {
    createMediaMutation({
      variables: {
        file: file,
      },
    });
  };

  const handleManualInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      const files = event.target.files;
      if (files.length > 10) {
        enqueueSnackbar(
          "Max amount of simultaneous uploads is 10 media files",
          {
            variant: "error",
          }
        );
        return null;
      }
      for (let i = 0; i < files.length; i++) {
        if (validateFiles(files[i])) {
          handleUpload(files[i]);
        } else {
        }
      }
    }
    event.target.value = "";
  };

  const handleFileDrop = (files: FileList) => {
    if (files.length > 10) {
      enqueueSnackbar("Max amount of simultaneous uploads is 10 media files", {
        variant: "error",
      });
      return null;
    }
    for (let i = 0; i < files.length; i++) {
      if (validateFiles(files[i])) {
        handleUpload(files[i]);
      }
    }
  };

  const handleOnDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(false);
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileDrop(files);
    }
    setOnDragEnterToggle(false);
  };

  const handleOnClick = () => {
    if (inputRef) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const handleDeleteMedia = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (openDialog.item?.id) {
      const id = openDialog.item.id;
      deleteMediaMutation({
        variables: {
          id: +id,
        },
        update(cache) {
          cache.modify({
            fields: {
              allMedias(existingMedias, { readField }) {
                return existingMedias.filter(
                  (taskRef: any) => id !== readField("id", taskRef)
                );
              },
            },
          });
        },
      });
    } else {
      enqueueSnackbar("Failed to find ID!", { variant: "error" });
    }
  };

  const handleOnConfirm = () => {
    dialogContext?.onChange?.(selectedMedia);
    onClose?.();
    enqueueSnackbar(`${selectedMedia.length} media selected!`, {
      variant: "info",
    });
  };

  useEffect(() => {
    if (dialogContext?.selectedMedia?.length) {
      setSelectedMedia([...dialogContext.selectedMedia]);
    }
  }, [dialogContext?.selectedMedia]);

  const DialogOrNotContent = isDialog ? DialogContent : PaperContainer;
  const DialogOrNotTitle = isDialog ? DialogTitle : PaperTitle;

  return (
    <>
      <DialogOrNotTitle>Media</DialogOrNotTitle>
      <DialogOrNotContent>
        <Button
          variant={onDragEnterToggle ? "text" : "outlined"}
          color={onDragEnterToggle ? "secondary" : "inherit"}
          onDragOver={handleOnDragOver}
          onDragEnter={handleOnDragEnter}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDragDrop}
          className={
            onDragEnterToggle
              ? classes.uploadButtonActive
              : classes.uploadButton
          }
          onClick={handleOnClick}
        >
          <PublishOutlinedIcon className={classes.uploadAreaIcon} />
        </Button>
        <input
          ref={inputRef}
          multiple={dialogContext?.max && dialogContext?.max > 1 ? true : false}
          accept={
            dialogContext?.type === "image"
              ? ALLOWED_IMAGE_TYPES.join(",")
              : dialogContext?.type === "audio"
              ? ALLOWED_AUDIO_TYPES.join(",")
              : ALLOWED_MEDIA_TYPES.join(",")
          }
          type="file"
          className={classes.hidden}
          tabIndex={-1}
          onChange={handleManualInput}
        />
        <div className={classes.container}>
          <DataHandlerComponent
            error={Boolean(error)}
            hasData={Boolean(data?.allMedias.length)}
            loading={loading}
          >
            {data?.allMedias.length
              ? data.allMedias.map((item) => {
                  const handleOpenPreviewDialog = () => {
                    setOpenDialog({
                      open: true,
                      item: item,
                      type: "preview",
                    });
                  };
                  const handleOpenDeleteDialog = () => {
                    setOpenDialog({
                      open: true,
                      item: item,
                      type: "delete",
                    });
                  };

                  const isSelected = selectedMedia.findIndex(
                    (x) => x.id === item.id
                  );

                  const handleSelectMedia = () => {
                    const newSelectedMedia = [...selectedMedia];
                    const index = newSelectedMedia.findIndex(
                      (x) => x.id === item.id
                    );

                    if (index === -1) {
                      if (
                        dialogContext?.max
                          ? selectedMedia.length >= dialogContext.max
                          : selectedMedia.length
                      ) {
                        enqueueSnackbar(
                          `You can only select up to ${dialogContext?.max} media!`,
                          {
                            variant: "warning",
                          }
                        );
                        return null;
                      }

                      const itemExists = data?.allMedias.find(
                        (x) => x.id === item.id
                      );
                      if (itemExists) {
                        newSelectedMedia.push(itemExists);
                        setSelectedMedia([...newSelectedMedia]);
                      } else {
                        enqueueSnackbar(`Something went wrong!`, {
                          variant: "error",
                        });
                      }
                    } else {
                      // console.log("index", index);
                      newSelectedMedia.splice(index, 1);
                      setSelectedMedia([...newSelectedMedia]);
                    }
                  };

                  return (
                    <MediaItem
                      onClick={
                        isDialog ? handleSelectMedia : handleOpenPreviewDialog
                      }
                      key={item.id}
                      item={item}
                      handleOpenDeleteDialog={handleOpenDeleteDialog}
                      handleOpenPreviewDialog={handleOpenPreviewDialog}
                      isSelected={isSelected === -1 ? 0 : isSelected + 1}
                    />
                  );
                })
              : null}
          </DataHandlerComponent>
        </div>

        <DeleteDialog
          open={openDialog.open && openDialog.type === "delete"}
          onClose={handleCloseDialog}
          title={`Delete ${openDialog.item?.fileName}`}
          description="This action is permanent"
          mutation={handleDeleteMedia}
        />
        <MediaPreviewDialog
          open={openDialog.open && openDialog.type === "preview"}
          onClose={handleCloseDialog}
          item={openDialog.item}
        />
        <LoadingBackdrop
          loading={loadingCreateMutation || loadingDeleteMutation}
        />
      </DialogOrNotContent>

      {isDialog ? (
        <DialogActions>
          <Typography className={classes.selectText} color="textSecondary">
            Select media files above and then click on Confirm
          </Typography>

          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" onClick={handleOnConfirm}>
            Confirm
          </Button>
        </DialogActions>
      ) : null}
    </>
  );
};
