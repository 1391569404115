import { gql } from "@apollo/client";

export interface ICreateAdminData {
  createAdmin: boolean;
}

export interface ICreateAdminVars {
  data: {
    token: string;
    name: string;
    password: string;
  };
}

export const CREATE_ADMIN = gql`
  mutation createAdmin($data: AdminCreateInput!) {
    createAdmin(data: $data)
  }
`;
