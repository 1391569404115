import {
  LogoutOutlined as LogoutOutlinedIcon,
  PersonOutlined as PersonOutlinedIcon,
} from "@mui/icons-material";
import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStyles } from "./Header.styles";
import logo from "../../assets/logo.png";
import { ResetPasswordDialog } from "./components";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ILogoutCmsData,
  ISwitchPersonRoleSubjectData,
  ISwitchPersonRoleSubjectVars,
  LOGOUT,
  SWITCH_PERSON_ROLE_SUBJECT,
} from "../../apollo/mutations";
import { ContextProvider } from "../../utils";
import { LoadingBackdrop } from "..";
import { ICurrentUser } from "../../apollo/queries";

export const Header: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const { user, setUser } = useContext(ContextProvider);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [logoutMutation, { loading, client }] = useMutation<
    ILogoutCmsData,
    null
  >(LOGOUT, {
    onCompleted: async () => {
      enqueueSnackbar("Logged out", { variant: "success" });
      setUser(undefined);
      await client.clearStore();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  const [
    switchPersonRoleSubjectMutation,
    { loading: loadingSwitchPersonRoleMutation },
  ] = useMutation<ISwitchPersonRoleSubjectData, ISwitchPersonRoleSubjectVars>(
    SWITCH_PERSON_ROLE_SUBJECT,
    {
      onCompleted: async (data) => {
        if (user !== undefined && user !== null) {
          const newUser: ICurrentUser = {
            ...user,
            activePersonRoleSubject: data.switchPersonRoleSubject,
          };
          setUser(newUser);
          enqueueSnackbar(
            `Switched to ${data?.switchPersonRoleSubject?.subject.locale?.name}.`,
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(
            `There was an issue when switched to ${data?.switchPersonRoleSubject?.subject.locale?.name}. Try to refresh your page.`,
            { variant: "error" }
          );
        }

        await apolloClient.clearStore();
        navigate("/");
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
      //TODO: Update cache
    }
  );

  const handleLogout = (event: Event | React.SyntheticEvent) => {
    logoutMutation();
    handleClose(event);
  };

  const handleToggle = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handleCloseResetPasswordDialog = () => {
    setResetPasswordDialog(false);
  };

  const handleOpenResetPasswordDialog = () => {
    setResetPasswordDialog(true);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenProfile(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenProfile(false);
    } else if (event.key === "Escape") {
      setOpenProfile(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openProfile);
  useEffect(() => {
    if (prevOpen.current === true && openProfile === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openProfile;
  }, [openProfile]);

  //TODO: Fix navlink margin
  return (
    <>
      <div className={classes.root}>
        <Link className={classes.logo} to="/">
          <img src={logo} className={classes.logo} alt="Naratour logo" />
        </Link>
        {user ? (
          <>
            <div className={classes.subjectNameDiv}>
              <Typography variant="h5" component="p" color="secondary">
                {user.activePersonRoleSubject?.subject?.locale?.name}
              </Typography>
            </div>
            <div>
              <IconButton
                ref={anchorRef}
                onClick={handleToggle}
                className={cx(classes.iconButton)}
              >
                <PersonOutlinedIcon
                  color={openProfile ? "primary" : undefined}
                />
              </IconButton>
              <Popper
                open={openProfile}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "right bottom"
                          : "right top",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openProfile}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem divider onClick={handleClose}>
                            <ListItemText
                              secondary={user ? user?.email : "undefined email"}
                            >
                              {user ? user?.name : "undefined user"}
                            </ListItemText>
                          </MenuItem>
                          <MenuItem disabled divider>
                            <ListItemText>
                              {user?.activePersonRoleSubject?.role.locale?.name
                                ? user?.activePersonRoleSubject?.role.locale
                                    .name
                                : "undefined role"}
                            </ListItemText>
                          </MenuItem>

                          {user?.allPersonRoleSubjects?.length ? (
                            user?.allPersonRoleSubjects.map((item, i) => {
                              const isSelected =
                                user.activePersonRoleSubject?.id === item.id;

                              const handleSwithOrganisation = () => {
                                if (!isSelected) {
                                  switchPersonRoleSubjectMutation({
                                    variables: {
                                      id: +item.id,
                                    },
                                  });
                                }
                              };

                              return (
                                <MenuItem
                                  key={item.id}
                                  onClick={handleSwithOrganisation}
                                >
                                  <ListItemIcon>
                                    <Avatar
                                      className={cx(
                                        classes.avatarSmall,
                                        isSelected
                                          ? classes.avatarSelected
                                          : undefined
                                      )}
                                    >
                                      {i + 1}
                                    </Avatar>
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      classes: {
                                        root: cx(
                                          isSelected
                                            ? classes.menuSmallTextActive
                                            : classes.menuSmallText
                                        ),
                                      },
                                    }}
                                  >
                                    {item.subject.locale?.name}
                                  </ListItemText>
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem disabled>
                              <ListItemText
                                primaryTypographyProps={{
                                  classes: {
                                    root: classes.menuSmallText,
                                  },
                                }}
                              >
                                You are not assigned to any roles!
                              </ListItemText>
                            </MenuItem>
                          )}
                          <Divider />

                          <MenuItem onClick={handleOpenResetPasswordDialog}>
                            Change password
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                              <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <ResetPasswordDialog
                open={resetPasswordDialog}
                onClose={handleCloseResetPasswordDialog}
              />

              <LoadingBackdrop
                loading={loading || loadingSwitchPersonRoleMutation}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
