import { Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./OneTabOnlyMessage.styles";
// import { BroadcastChannel, createLeaderElection } from "broadcast-channel";

export const OneTabOnlyMessage: FC = () => {
  const { classes } = useStyles();

  //TODO: There are no docs on how to start a leader
  // const setNewLeader = async () => {
  //   const channel = new BroadcastChannel("oneTabOnly");
  //   const elector = createLeaderElection(channel);
  //   await elector.applyOnce();
  // };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography paragraph>
          At the moment, Naratour CMS can only work in one browser tab. To use
          Naratour in another tab, please open it in incognito mode or another
          browser.
        </Typography>
        {/* <Button onClick={setNewLeader} variant="contained">
          Use in this tab
        </Button> */}
      </Paper>
    </div>
  );
};
