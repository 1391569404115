import { gql } from "@apollo/client";

export interface ICreateMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
}

export interface ICreateMediaData {
  createMedia: ICreateMedia;
}
export interface ICreateMediaVars {
  file: File;
}

export const CREATE_MEDIA = gql`
  mutation createMedia($file: Upload!) {
    createMedia(file: $file) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
    }
  }
`;
