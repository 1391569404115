import { gql } from "@apollo/client";
import { IMedia } from "../../queries";

export interface ICreateStation {
  id: string;
  published: boolean;
  textCode: string | null;
  gallery: IMedia[] | null;
  locale: {
    id: string;
    title: string | null;
    description: string | null;
    audioGuideText: string | null;
    audioGuide: IMedia | null;
  };
  created: string;
}

export interface ICreateStationData {
  createStation: ICreateStation;
}
export interface ICreateStationVars {
  data: {
    textCode: string | undefined;
    classicTourId: number;
  };
  dataLocale: {
    title: string | undefined;
    description: string | undefined;
    audioGuideText: string | undefined;
    audioGuideId: number | undefined;
    gallery: number[] | undefined;
  };
}

export const CREATE_STATION = gql`
  mutation createStation(
    $data: StationCreateInput!
    $dataLocale: StationLocaleCreateInitialInput!
  ) {
    createStation(data: $data, dataLocale: $dataLocale) {
      id
      published
      textCode
      locale {
        id
        title
        description
        audioGuideText
        audioGuide {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
      }
      children {
        id
      }
      created
    }
  }
`;
