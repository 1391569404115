import { gql } from "@apollo/client";

//*-------Update other
export interface IUpdateSubjectOther {
  id: string;
  phone: string | null;
  email: string | null;
  header: {
    id: string;
  } | null;
}

export interface IUpdateSubjectOtherData {
  updateSubjectOther: IUpdateSubjectOther;
}
export interface IUpdateSubjectOtherVars {
  data: {
    phone: string | undefined | null;
    email: string | undefined | null;
    headerId: number | undefined | null;
  };
}

export const UPDATE_SUBJECT_OTHER = gql`
  mutation updateSubjectOther($data: SubjectOtherUpdateInput!) {
    updateSubjectOther(data: $data) {
      id
      email
      phone
      header {
        id
      }
    }
  }
`;

//*-------Update locale

export interface IUpdateSubjectLocale {
  id: string;
  name: string | null;
  about: string | null;
  extraInfo: string | null;
  openingHoursInfo: string | null;
}

export interface IUpdateSubjectLocaleData {
  updateSubjectLocale: IUpdateSubjectLocale;
}
export interface IUpdateSubjectLocaleVars {
  id: number;
  data: {
    name: string | undefined | null;
    about: string | undefined | null;
    extraInfo: string | undefined | null;
    openingHoursInfo: string | undefined | null;
  };
}

export const UPDATE_SUBJECT_LOCALE = gql`
  mutation updateSubjectLocale($id: ID!, $data: SubjectLocaleUpdateInput!) {
    updateSubjectLocale(id: $id, data: $data) {
      id
      name
      about
      extraInfo
      openingHoursInfo
    }
  }
`;

//*-------Create locale

export interface ICreateSubjectLocale {
  id: string;
  name: string | null;
  about: string | null;
  extraInfo: string | null;
  openingHoursInfo: string | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateSubjectLocaleData {
  createSubjectLocale: ICreateSubjectLocale;
}
export interface ICreateSubjectLocaleVars {
  data: {
    name: string | undefined;
    about: string | undefined;
    extraInfo: string | undefined;
    openingHoursInfo: string | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_SUBJECT_LOCALE = gql`
  mutation createSubjectLocale($data: SubjectLocaleCreateInput!) {
    createSubjectLocale(data: $data) {
      id
      name
      about
      extraInfo
      openingHoursInfo
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ UPDATE PUBLISHED

export interface IUpdateSubjectPublished {
  id: string;
  published: boolean;
}

export interface IUpdateSubjectPublishedData {
  updateSubjectPublished: IUpdateSubjectPublished | null;
}
export interface IUpdateSubjectPublishedVars {
  publish: boolean;
  checkOnly: boolean;
}

export const UPDATE_SUBJECT_PUBLISHED = gql`
  mutation updateSubjectPublished($checkOnly: Boolean!, $publish: Boolean!) {
    updateSubjectPublished(checkOnly: $checkOnly, publish: $publish) {
      id
      published
    }
  }
`;
