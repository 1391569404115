import { FC } from "react";
import { Dialog, LinearProgress } from "@mui/material";

interface IProps {
  loading: boolean;
}

export const LoadingBackdrop: FC<IProps> = (props) => {
  const { loading } = props;

  const PaperProps = {
    style: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };

  return (
    <Dialog open={loading} fullScreen PaperProps={PaperProps}>
      <LinearProgress color="primary" />
    </Dialog>
  );
};
