import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "./NavbarItem.styles";
import { INavbarData } from "../../Navbar.data";
import { Divider, Typography } from "@mui/material";

interface IProps {
  data: INavbarData;
  divider: boolean;
}

export const NavbarItem: FC<IProps> = (props) => {
  const { title, route, icon: Icon, external } = props.data;
  const { divider } = props;
  const { cx, classes } = useStyles();

  return (
    <>
      {external ? (
        <a
          className={cx(classes.linkDiv)}
          href={route}
          target={external ? "__blank" : undefined}
          referrerPolicy={"no-referrer"}
        >
          <Icon className={cx(classes.icon)} />
          <Typography className={cx(classes.link)}>{title}</Typography>
        </a>
      ) : (
        <NavLink
          className={({ isActive }) =>
            cx(classes.linkDiv, isActive ? classes.linkDivActive : "")
          }
          to={external ? { pathname: route } : route}
          target={external ? "__blank" : undefined}
        >
          <Icon className={cx(classes.icon)} />
          <Typography className={cx(classes.link)}>{title}</Typography>
        </NavLink>
      )}
      {divider ? <Divider className={cx(classes.divider)} /> : null}
    </>
  );
};
