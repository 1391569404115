import { gql } from "@apollo/client";

export interface IReceptionPerson {
  id: string;
  name: string;
  email: string;
  expired: string;
  validated: boolean;
}

export interface IReceptionPersonData {
  oneReceptionPerson?: IReceptionPerson;
}

export interface IReceptionPersonVars {
  personId: number;
}

export const ONE_RECEPTION_PERSON = gql`
  query oneReceptionPerson($personId: ID!) {
    oneReceptionPerson(personId: $personId) {
      id
      name
      email
      expired
      validated
    }
  }
`;
