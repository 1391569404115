import { gql } from "@apollo/client";
import { IMedia } from "../../queries";

export interface ICreateClassicTour {
  id: string;
  duration: number | null;
  published: boolean;
  locale: {
    id: string;
    title: string | null;
    description: string | null;
    gallery: IMedia[] | null;
  };
  created: string;
}

export interface ICreateClassicTourData {
  createClassicTour: ICreateClassicTour;
}
export interface ICreateClassicTourVars {
  data: {
    duration: string | undefined;
    stations: number[] | undefined;
  };
  dataLocale: {
    title: string | undefined;
    description: string | undefined;
    gallery: number[] | undefined;
  };
}

export const CREATE_CLASSIC_TOUR = gql`
  mutation createClassicTour(
    $data: ClassicTourCreateInput!
    $dataLocale: ClassicTourLocaleCreateInitialInput!
  ) {
    createClassicTour(data: $data, dataLocale: $dataLocale) {
      id
      duration
      published
      locale {
        id
        title
        description
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
      }
      created
    }
  }
`;
