import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      name: "checkpointTitle",
      label: "Checkpoint Title",
      placeholder: "A title for this Checkpoint",
      required: true,
    },
    localise: true,
  },
  directions: {
    inputProps: {
      name: "directions",
      label: "Directions",
      placeholder: "Directions to the challenge area",
      required: true,
    },
    localise: true,
    mainHelperText:
      "In this field visitors get a set of directions to guide themselves from one Checkpoint to the other.",
  },
  directionsHint: {
    inputProps: {
      name: "directionsHint",
      label: "Directions hint",
      placeholder: "Clear and precise directions to the challenge area.",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Textual hints assist visitors in finding the next Checkpoint.",
  },
  opening: {
    inputProps: {
      name: "checkpointOpening",
      label: "Checkpoint Opening",
      required: true,
    },
    localise: true,
    mainHelperText:
      "The Opening should tell the visitor that they have reached the specific Checkpoint and should direct him to the challenge area.",
  },
  challenge: {
    inputProps: {
      name: "checkpointChallenge",
      label: "Checkpoint Challenge",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Challenge can be a puzzle, code, riddle and it is best to gradually increase in difficulty from one location to the next.",
  },
  challengeHint: {
    inputProps: {
      name: "challengeHint",
      label: "Challenge hint",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Textual hints assist visitors in finding the Solution, but it does not provide the Solution itself.",
  },
  closing: {
    inputProps: {
      name: "checkpointClosing",
      label: "Checkpoint Closing",
      required: true,
    },
    localise: true,
    mainHelperText:
      "The Closing should include the solution to the challenge and mention the protagonist moving on to the next location.",
  },
  facts: {
    inputProps: {
      name: "interestingFact",
      label: "Interesting fact",
      required: true,
      placeholder: "Interesting fact related to this Checkpoint",
    },
    localise: true,
    mainHelperText:
      "Interesting fact section contains memorable stories and information about the particular checkpoint. Interesting facts should be connected to the main theme, interesting and worthy of retelling the information to another person.",
  },
  factsGallery: {
    inputProps: {
      name: "facts",
      label: "Facts gallery",
      placeholder: "",
    },
    rule: "media",
    localise: true,
  },
  directionsHintGallery: {
    inputProps: {
      name: "facts",
      label: "Directions hint gallery",
      placeholder: "",
      required: true,
    },
    rule: "media",
    localise: true,
    mainLocaleRequiredOnly: true,
  },
  challengeHintGallery: {
    inputProps: {
      name: "challengeHintGallery",
      label: "Challenge hint gallery",
      placeholder: "",
    },
    rule: "media",
    localise: true,
  },

  solution: {
    mainHelperText:
      "Solutions should be brief, consisting of one or two words at most. Multiple solutions can be added by comma (,) separation. Please note that spaces are also included in the Solution.",
    inputProps: {
      name: "solution",
      label: "Solution",
      placeholder: "5.12.1998.,05.12.1998.",
      required: true,
    },
    localise: true,
  },
});
