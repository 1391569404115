import { InMemoryCache, ApolloClient, ApolloLink } from "@apollo/client";
//@ts-ignore
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (process.env.NODE_ENV !== "production") {
//       if (graphQLErrors) {
//         for (let err of graphQLErrors) {
//           switch (err.extensions.code) {
//             case "UNAUTHENTICATED":
//               console.log("Error link UNAUTHENTICATED");
//               apolloClient
//                 .mutate({ mutation: LOGOUT })
//                 .then(() => setUser(undefined));

//               return forward(operation);
//           }
//         }
//       }
//       if (networkError) {
//         console.log(`[Network error]: ${networkError}`);
//       }
//     }
//   }
// );

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    // errorLink,
    createUploadLink({
      uri: process.env.REACT_APP_API_ENDPOINT_GRAPHQL,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      InteractiveTourLocale: {
        fields: {
          gallery: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming;
            },
          },
        },
      },
      CheckpointLocale: {
        fields: {
          challengeHintGallery: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming;
            },
          },
          directionsHintGallery: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming;
            },
          },
          factsGallery: {
            merge(existing, incoming, { mergeObjects }) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
