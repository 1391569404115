import { gql } from "@apollo/client";

export interface ICheckAdminInviteData {
  checkAdminInvite: boolean;
}

export interface ICheckAdminInviteVars {
  token: string;
}

export const CHECK_ADMIN_INVITE = gql`
  mutation checkAdminInvite($token: NonEmptyString!) {
    checkAdminInvite(token: $token)
  }
`;

export interface IRevokePersonRoleSubjectInvitation {
  id: string;
  expired: string | null;
}

export interface IRevokePersonRoleSubjectInvitationData {
  revokePersonRoleSubjectInvitation: IRevokePersonRoleSubjectInvitation;
}

export interface IRevokePersonRoleSubjectInvitationVars {
  id: number;
}

export const REVOKE_PERSON_ROLE_SUBJECT_INVITATION = gql`
  mutation revokePersonRoleSubjectInvitation($id: ID!) {
    revokePersonRoleSubjectInvitation(id: $id) {
      id
      expired
    }
  }
`;

export interface IResendPersonRoleSubjectInvitation {
  id: string;
  email: string;
  locale: {
    id: string;
    name: string;
  } | null;
  created: string;
  expired: string | null;
}

export interface IResendPersonRoleSubjectInvitationData {
  //*Created / Updated
  resendPersonRoleSubjectInvitation: [
    IResendPersonRoleSubjectInvitation,
    IResendPersonRoleSubjectInvitation
  ];
}

export interface IResendPersonRoleSubjectInvitationVars {
  id: number;
}

export const RESEND_PERSON_ROLE_SUBJECT_INVITATION = gql`
  mutation resendPersonRoleSubjectInvitation($id: ID!) {
    resendPersonRoleSubjectInvitation(id: $id) {
      id
      email

      created
      expired

      role {
        id
        locale {
          id
          name
        }
      }
    }
  }
`;
