import { gql } from "@apollo/client";

//*-------Update other
export interface IUpdateClassicTourOther {
  id: string;
  duration: number;
}

export interface IUpdateClassicTourOtherData {
  updateClassicTourOther: IUpdateClassicTourOther;
}
export interface IUpdateClassicTourOtherVars {
  id: number;
  data: {
    duration: number | undefined | null;
  };
}

export const UPDATE_CLASSIC_TOUR_OTHER = gql`
  mutation updateClassicTourOther(
    $id: ID!
    $data: ClassicTourOtherUpdateInput!
  ) {
    updateClassicTourOther(id: $id, data: $data) {
      id
      duration
    }
  }
`;

//*-------Update locale

export interface IUpdateClassicTourLocale {
  id: string;
  title: string | null;
  description: string | null;
  gallery:
    | {
        id: string;
      }[]
    | null;
}

export interface IUpdateClassicTourLocaleData {
  updateClassicTourLocale: IUpdateClassicTourLocale;
}
export interface IUpdateClassicTourLocaleVars {
  id: number;
  data: {
    title: string | undefined | null;
    description: string | undefined | null;
    gallery: number[] | undefined | null;
  };
}

export const UPDATE_CLASSIC_TOUR_LOCALE = gql`
  mutation updateClassicTourLocale(
    $id: ID!
    $data: ClassicTourLocaleUpdateInput!
  ) {
    updateClassicTourLocale(id: $id, data: $data) {
      id
      title
      description
      gallery(localeSpecific: true) {
        id
      }
    }
  }
`;

//*-------Create

export interface ICreateClassicTourLocale {
  id: string;
  title: string;
  description: string;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateClassicTourLocaleData {
  createClassicTourLocale: ICreateClassicTourLocale;
}
export interface ICreateClassicTourLocaleVars {
  classicTourId: number;
  data: {
    title: string | undefined;
    description: string | undefined;
    gallery: number[] | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_CLASSIC_TOUR_LOCALE = gql`
  mutation createClassicTourLocale(
    $classicTourId: ID!
    $data: ClassicTourLocaleCreateInput!
  ) {
    createClassicTourLocale(classicTourId: $classicTourId, data: $data) {
      id
      title
      description
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ SET TOUR_STATION

export interface IUpdateClassicTourStation {
  id: string;
  allStations: {
    id: string;
  }[];
}

export interface IUpdateClassicTourStationData {
  updateClassicTourStation: IUpdateClassicTourStation;
}
export interface IUpdateClassicTourStationVars {
  id: number;
  data: {
    stations: { id: number; order: number; parentId?: number | undefined }[];
  };
}

export const UPDATE_CLASSIC_TOUR_STATION = gql`
  mutation updateClassicTourStation(
    $id: ID!
    $data: ClassicTourStationUpdateInput!
  ) {
    updateClassicTourStation(id: $id, data: $data) {
      id
      allStations {
        id
      }
    }
  }
`;

//*------ UPDATE PUBLISHED

export interface IUpdateClassicTourPublished {
  id: string;
  published: boolean;
}

export interface IUpdateClassicTourPublishedData {
  updateClassicTourPublished: IUpdateClassicTourPublished | null;
}
export interface IUpdateClassicTourPublishedVars {
  id: number;
  publish: boolean;
  checkOnly: boolean;
}

export const UPDATE_CLASSIC_TOUR_PUBLISHED = gql`
  mutation updateClassicTourPublished(
    $id: ID!
    $checkOnly: Boolean!
    $publish: Boolean!
  ) {
    updateClassicTourPublished(
      id: $id
      checkOnly: $checkOnly
      publish: $publish
    ) {
      id
      published
    }
  }
`;
