import { gql } from "@apollo/client";
import { ICurrentUser } from "../../queries";
import {
  F_ACTIVE_PERSON_ROLE_SUBJECT,
  F_PERSON,
} from "../../queries/fragments";

export interface ILoginCmsData {
  loginCms: ICurrentUser;
}

export interface ILoginCmsVars {
  data: {
    email: string;
    password: string;
    remember: boolean;
  };
}

export const LOGIN = gql`
  ${F_PERSON}
  ${F_ACTIVE_PERSON_ROLE_SUBJECT}
  mutation loginCms($data: LoginCmsInput!) {
    loginCms(data: $data) {
      ...FPerson
      activePersonRoleSubject {
        ...FActivePersonRoleSubject
      }
    }
  }
`;
