import { gql } from "@apollo/client";
import { IInteractiveTour } from "../interactiveTour/interactiveTour.query";
import { IClassicTour } from "../classicTour/classicTour.query";

export interface IProduct {
  id: string;
  price: number;
  created: string;
  interactiveTour: IInteractiveTour | null;
  classicTour: IClassicTour | null;
}

export interface IProductData {
  oneProduct: IProduct;
}

export interface IProductVars {
  id: number;
}

export const ONE_PRODUCT = gql`
  query oneProducts($id: ID!) {
    oneProduct(id: $id) {
      id
      price
      created
      classicTour {
        id
        published
        locale(lang: "en-GB") {
          title
        }
      }
      interactiveTour {
        id
        published
        locale(lang: "en-GB") {
          title
        }
      }
    }
  }
`;

export interface IProductsData {
  allProducts: IProduct[];
}

export interface IProductsVars {
  noFree?: boolean;
}

export const ALL_PRODUCTS = gql`
  query allProducts($noFree: Boolean) {
    allProducts(noFree: $noFree) {
      id
      price
      created
      classicTour {
        id
        locale(lang: "en-GB") {
          title
        }
        published
      }
      interactiveTour {
        id
        locale(lang: "en-GB") {
          title
        }
        published
      }
    }
  }
`;
