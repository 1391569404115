import { gql } from "@apollo/client";

export interface ICancelReceipt {
  id: string;
  cancelledByName: string;
  cancelledByEmail: string;
  expired: string;
}

export interface ICancelReceiptData {
  cancelReceipt: ICancelReceipt;
}
export interface ICancelReceiptVars {
  id: number;
}

export const CANCEL_RECEIPT = gql`
  mutation cancelReceipt($id: ID!) {
    cancelReceipt(id: $id) {
      id
      cancelledByName
      cancelledByEmail
      expired
    }
  }
`;
