import { gql } from "@apollo/client";

export interface IDeleteOpeningHourVars {
  id: number;
}

export interface IDeleteOpeningHour {
  id: string;
}

export interface IDeleteOpeningHourData {
  deleteOpeningHour: IDeleteOpeningHour;
}

export const DELETE_OPENING_HOUR = gql`
  mutation deleteOpeningHour($id: ID!) {
    deleteOpeningHour(id: $id) {
      id
    }
  }
`;
