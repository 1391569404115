import { FC } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { useStyles } from "./ResetPasswordPage.styles";
import { useSnackbar } from "notistack";
import { initialInputData } from "./ResetPasswordPage.inputs";
import { useNavigate, useParams } from "react-router";
import {
  RESET_PASSWORD,
  IResetPasswordData,
  IResetPasswordVars,
} from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../../components";
import { useForm } from "../../../utils/hooks/useForm";

export const ResetPasswordPage: FC = () => {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();

  const { inputFields, inputFieldNames, validateForm } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [resetPasswordMutation, { loading, data }] = useMutation<
    IResetPasswordData,
    IResetPasswordVars
  >(RESET_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      if (resetPassword) {
        enqueueSnackbar("Password has been reset.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error! Please try again", {
          variant: "error",
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleResetPassword = () => {
    if (validateForm(inputFieldNames)) {
      if (!token) {
        enqueueSnackbar("Token not found!", { variant: "error" });
        return;
      }
      resetPasswordMutation({
        variables: {
          data: {
            token: token,
            password: inputFields.password.inputProps.value,
            passwordRepeat: inputFields.passwordRepeat.inputProps.value,
          },
        },
      });
    }
  };

  // const handleNavigateToForgotPassword = () => {
  //   navigation("/forgot-password");
  // };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph component="h1" variant="h5">
            Reset password
          </Typography>

          <Typography paragraph>
            {data?.resetPassword
              ? "Your password has been successfully reset!"
              : "Please enter your new password."}
          </Typography>
        </div>
        {data?.resetPassword ? null : (
          <>
            <TextField
              {...inputFields.password.inputProps}
              autoFocus
              margin="normal"
              fullWidth
            />
            <TextField
              {...inputFields.passwordRepeat.inputProps}
              margin="normal"
              fullWidth
            />
            {/* <Button
          className={cx(classes.button)}
          variant="outlined"
          fullWidth
          onClick={handleNavigateToForgotPassword}
        >
          Resend email
        </Button> */}
            <Button
              variant="contained"
              fullWidth
              className={cx(classes.button)}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </>
        )}
      </Paper>
      <LoadingBackdrop loading={loading} />
    </main>
  );
};
