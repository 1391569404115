import { gql } from "@apollo/client";
import { IActivePersonRoleSubject } from "../../mutations";
import { F_ACTIVE_PERSON_ROLE_SUBJECT, F_PERSON } from "../fragments";

export interface ICurrentUser {
  id: string;
  name: string;
  email: string;
  activePersonRoleSubject: IActivePersonRoleSubject | null;
  allPersonRoleSubjects:
    | {
        id: string;
        subject: {
          id: string;
          locale: {
            id: string;
            name: string;
          } | null;
        };
      }[]
    | null;
}

export interface ICurrentUserData {
  currentUser: ICurrentUser;
}

export const CURRENT_USER = gql`
  ${F_PERSON}
  ${F_ACTIVE_PERSON_ROLE_SUBJECT}
  query currentUser {
    currentUser {
      ...FPerson
      activePersonRoleSubject {
        ...FActivePersonRoleSubject
      }
    }
  }
`;
