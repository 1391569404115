import { gql } from "@apollo/client";

export interface IResetPasswordData {
  resetPassword: boolean;
}

export interface IResetPasswordVars {
  data: {
    token: string;
    password: string;
    passwordRepeat: string;
  };
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput) {
    resetPassword(data: $data)
  }
`;
