import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  cancelledStatus: {
    backgroundColor: theme.palette.error.main,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  details: {
    whiteSpace: "pre-line",
  },
  hidden: {
    visibility: "hidden",
  },
}));
