import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  tabPanel: {
    padding: `${theme.spacing(2)} 0`,
    flex: 1,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  inputWidth: {
    flex: 1,
  },
}));
