import { gql } from "@apollo/client";

//*-------Delete locale
export interface IDeleteSubjectLocale {
  id: string;
  localeId: string;
}

export interface IDeleteSubjectLocaleData {
  deleteSubjectLocale: IDeleteSubjectLocale;
}
export interface IDeleteSubjectLocaleVars {
  id: number;
}

export const DELETE_SUBJECT_LOCALE = gql`
  mutation deleteSubjectLocale($id: ID!) {
    deleteSubjectLocale(id: $id) {
      id
      localeId
    }
  }
`;
