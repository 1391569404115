import { gql } from "@apollo/client";
export interface IDeleteInteractiveTour {
  id: string;
}
export interface IDeleteInteractiveTourData {
  deleteInteractiveTour: IDeleteInteractiveTour;
}
export interface IDeleteInteractiveTourVars {
  id: number;
}
export const DELETE_INTERACTIVE_TOUR = gql`
  mutation deleteInteractiveTour($id: ID!) {
    deleteInteractiveTour(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteInteractiveTourLocale {
  id: string;
  localeId: string;
}

export interface IDeleteInteractiveTourLocaleData {
  deleteInteractiveTourLocale: IDeleteInteractiveTourLocale;
}
export interface IDeleteInteractiveTourLocaleVars {
  id: number;
}

export const DELETE_INTERACTIVE_TOUR_LOCALE = gql`
  mutation deleteInteractiveTourLocale($id: ID!) {
    deleteInteractiveTourLocale(id: $id) {
      id
      localeId
    }
  }
`;
