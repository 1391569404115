import { gql } from "@apollo/client";

export interface IUpdateOpeningHour {
  id: string;
  weekday: number;
  startHour: string;
  endHour: string;
}

export interface IUpdateOpeningHourData {
  updateOpeningHour: IUpdateOpeningHour;
}

export interface IUpdateOpeningHourVars {
  id: number;
  data: {
    weekday: number;
    startHour: string;
    endHour: string;
  };
}

export const UPDATE_OPENING_HOUR = gql`
  mutation updateOpeningHour($id: ID!, $data: OpeningHourUpdateInput!) {
    updateOpeningHour(id: $id, data: $data) {
      id
      weekday
      startHour
      endHour
    }
  }
`;
