import { TablePagination } from "@mui/material";
import { FC, useEffect, useState } from "react";
// import { useStyles } from "./Pagination.styles";
import { ITEMS_PER_PAGE } from "../../utils";

interface IProps {
  setCurrentIndexes: ({ start, end }: { start: number; end: number }) => void;
  data: any[] | undefined;
}

export const Pagination: FC<IProps> = (props) => {
  const { data, setCurrentIndexes } = props;
  // const { classes } = useStyles();
  const [itemOffset, setItemOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [page, setPage] = useState(0);

  const iconButtonProps: any = {
    color: "primary",
  };

  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + rowsPerPage;
      setCurrentIndexes({ start: itemOffset, end: endOffset });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, data, rowsPerPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (data && data.length) {
      const newOffset = (newPage * rowsPerPage) % data.length;
      setItemOffset(newOffset);
      setPage(newPage);
    }
  };

  //TODO: FIX NUMBERING ON OTHER PAGES
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (data) {
      const newRows = parseInt(event.target.value, 10);
      const newOffset = 0 % data.length;
      setItemOffset(newOffset);
      setRowsPerPage(newRows);
      const endOffset = itemOffset + newRows;
      setCurrentIndexes({ start: itemOffset, end: endOffset });
      setPage(0);
    }
  };

  if (!data || !data.length) {
    return null;
  }

  return (
    <TablePagination
      component="div"
      count={data.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={iconButtonProps}
      backIconButtonProps={iconButtonProps}
    />
  );
};
