import { gql } from "@apollo/client";

//*-------Update other
export interface IUpdateStationOther {
  id: string;
  gallery: {
    id: string;
    fileName: string;
    bucketPath: string;
    byteSize: number;
    mimeType: string;
    extension: string;
  }[];
}

export interface IUpdateStationOtherData {
  updateStationOther: IUpdateStationOther;
}
export interface IUpdateStationOtherVars {
  id: number;
  data: {
    gallery: { id: number }[];
  };
}

export const UPDATE_STATION_OTHER = gql`
  mutation updateStationOther($id: ID!, $data: StationOtherUpdateInput!) {
    updateStationOther(id: $id, data: $data) {
      id
      gallery(localeSpecific: true) {
        id
        fileName
        bucketPath
        byteSize
        mimeType
        extension
      }
    }
  }
`;

//*-------Update locale

export interface IUpdateStationLocale {
  id: string;
  title: string;
  description: string;
  audioGuideText: string;
  audioGuide: {
    id: string;
  };
}

export interface IUpdateStationLocaleData {
  updateStationLocale: IUpdateStationLocale;
}
export interface IUpdateStationLocaleVars {
  id: number;
  data: {
    title: string | null | undefined;
    description: string | null | undefined;
    audioGuideText: string | null | undefined;
    audioGuideId: number | null | undefined;
    gallery: number[] | null | undefined;
  };
}

export const UPDATE_STATION_LOCALE = gql`
  mutation updateStationLocale($id: ID!, $data: StationLocaleUpdateInput!) {
    updateStationLocale(id: $id, data: $data) {
      id
      title
      description
      audioGuideText
      gallery(localeSpecific: true) {
        id
      }
      audioGuide {
        id
      }
    }
  }
`;

//*-------Create

export interface ICreateStationLocale {
  id: string;
  title: string | null;
  description: string | null;
  audioGuideText: string | null;
  audioGuide: { id: string } | null;
  gallery:
    | {
        id: string;
      }[]
    | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateStationLocaleData {
  createStationLocale: ICreateStationLocale;
}
export interface ICreateStationLocaleVars {
  stationId: number;
  data: {
    title: string | undefined;
    description: string | undefined;
    audioGuideText: string | undefined;
    gallery: number[] | undefined;
    audioGuideId: number | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_STATION_LOCALE = gql`
  mutation createStationLocale(
    $stationId: ID!
    $data: StationLocaleCreateInput!
  ) {
    createStationLocale(stationId: $stationId, data: $data) {
      id
      title
      description
      audioGuideText
      gallery(localeSpecific: true) {
        id
      }
      audioGuide {
        id
      }
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*-------Update sharing
export interface IUpdateStationSharing {
  id: string;
  textCode: string | null;
}

export interface IUpdateStationSharingData {
  updateStationOther: IUpdateStationSharing;
}
export interface IUpdateStationSharingVars {
  id: number;
  data: {
    textCode: string | undefined | null;
  };
}

export const UPDATE_STATION_SHARING = gql`
  mutation updateStationSharing($id: ID!, $data: StationSharingUpdateInput!) {
    updateStationSharing(id: $id, data: $data) {
      id
      textCode
    }
  }
`;

//*------ UPDATE PUBLISHED

export interface IUpdateStationPublished {
  id: string;
  published: boolean;
}

export interface IUpdateStationPublishedData {
  updateStationPublished: IUpdateStationPublished | null;
}
export interface IUpdateStationPublishedVars {
  id: number;
  publish: boolean;
  checkOnly: boolean;
}

export const UPDATE_STATION_PUBLISHED = gql`
  mutation updateStationPublished(
    $id: ID!
    $checkOnly: Boolean!
    $publish: Boolean!
  ) {
    updateStationPublished(id: $id, checkOnly: $checkOnly, publish: $publish) {
      id
      published
    }
  }
`;

//*------ UPDATE REORDER

export interface IUpdateStationOrder {
  id: string;
  order: boolean;
}

export interface IUpdateStationOrderData {
  updateStationOrder: IUpdateStationOrder;
}
export interface IUpdateStationOrderVars {
  beforeId: number | undefined;
  toId: number;
  nextId: number | undefined;
  parentId: number | null;
}

export const UPDATE_STATION_ORDER = gql`
  mutation updateStationOrder(
    $beforeId: ID
    $toId: ID!
    $nextId: ID
    $parentId: ID
  ) {
    updateStationOrder(
      beforeId: $beforeId
      nextId: $nextId
      toId: $toId
      parentId: $parentId
    ) {
      id
      order
    }
  }
`;
