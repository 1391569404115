import { gql } from "@apollo/client";

export const F_PERSON = gql`
  fragment FPerson on Person {
    id
    name
    email
    allPersonRoleSubjects {
      id
      subject {
        id
        locale {
          id
          name
        }
      }
    }
  }
`;
