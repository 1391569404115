import { gql } from "@apollo/client";

export interface IDeleteMedia {
  fileName: string;
  id: string;
}

export interface IDeleteMediaData {
  deleteMedia: IDeleteMedia;
}

export interface IDeleteMediaVars {
  id: number;
}

export const DELETE_MEDIA = gql`
  mutation deleteMedia($id: ID!) {
    deleteMedia(id: $id) {
      id
      fileName
    }
  }
`;
