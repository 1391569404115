import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  price: {
    inputProps: {
      label: "Price",
      name: "price",
      placeholder: "7,00",
      required: true,
    },
    mainHelperText: "Enter the price of the new product.",
  },
});
