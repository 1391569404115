import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `16px 20px`,
    zIndex: theme.zIndex.drawer + 2,

    top: 0,
    left: 0,
    right: 0,

    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: "solid",
  },
  logo: {
    height: 28,
  },
  subjectNameDiv: {
    flex: 1,
    borderLeftColor: theme.palette.grey[300],
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    marginLeft: 16,
    paddingLeft: 16,
  },

  link: {
    color: theme.palette.text.primary,
    padding: 8,
    textDecoration: "none",
    display: "inline-block",
    transition: "color 250ms ease-in-out",

    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:after": {
      content: "''",
      display: "block",
      marginTop: 2,
      height: "2px",
      background: theme.palette.primary.main,
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  },

  linkActive: {
    color: theme.palette.primary.main,
    padding: "0 8px 0 8px",
    textDecoration: "none",
    display: "inline-block",
    "&:after": {
      content: "''",
      display: "block",
      marginTop: 2,
      height: "2px",
      background: theme.palette.primary.main,
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  },

  iconButton: {
    marginLeft: 4,
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    "& svg": {
      transition: "0.25s all ease-in-out",
    },
    "&:hover svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.primary.main,
    },
  },

  //Profile
  active: {
    color: theme.palette.primary.main,
  },
  avatarSmall: {
    width: 22,
    height: 22,
    fontSize: 10,
  },
  menuSmallText: {
    fontSize: 12,
  },
  menuSmallTextActive: {
    fontSize: 12,
    fontWeight: 600,
  },
  avatarProfile: {
    // width: 34,
    // height: 34,
    marginRight: 8,
  },
  avatarSelected: {
    backgroundColor: theme.palette.primary.main,
  },
}));
