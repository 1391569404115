import { useGlobalStyles } from "../../../utils/theme";
import { FC, useContext } from "react";
import { ContextProvider } from "../../../utils";
import { DataHandlerComponent, PageLayout } from "../../../components";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import {
  ALL_PRODUCTS,
  IProductsData,
  IProductsVars,
} from "../../../apollo/queries";
import { Add as AddIcon } from "@mui/icons-material";
import { ProductItem } from "./components";
import { useNavigate } from "react-router-dom";

export const ProductListPage: FC = () => {
  const { user } = useContext(ContextProvider);
  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const navigate = useNavigate();

  const { loading, data, error } = useQuery<IProductsData, IProductsVars>(
    ALL_PRODUCTS
  );

  const handleCreateNewProduct = () => {
    navigate("new");
  };

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div className={globalClasses.paperTitle}>
          <div className={globalClasses.justifySpaceBetween}>
            <Typography variant="h6">Products</Typography>

            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={handleCreateNewProduct}
            >
              Add new Product
            </Button>
          </div>
        </div>
        <DataHandlerComponent
          hasData={Boolean(data?.allProducts?.length)}
          error={Boolean(error)}
          loading={loading}
        >
          <div className={globalClasses.paperContainer}>
            <Typography color="textSecondary">
              List of products available to users for purchase.
            </Typography>
            <TableContainer className={globalClasses.tableContainer}>
              <Table
                className={globalClasses.table}
                aria-label="List of products"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={32} align="left">
                      #
                    </TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell width={160} align="left">
                      Created
                    </TableCell>
                    <TableCell width={160} align="left">
                      Price (
                      {
                        user?.activePersonRoleSubject?.subject?.businessModel
                          ?.currency?.isoCode
                      }
                      )
                    </TableCell>
                    <TableCell width={90} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.allProducts?.length
                    ? data.allProducts.map((item, i) => {
                        return (
                          <ProductItem
                            key={item.id}
                            data={item}
                            index={i + 1}
                          />
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <InfoShowcase title="This only affects the price shown on receipts when you unlock access to content via reception. It has no effect on in-app purchases." /> */}
          </div>
        </DataHandlerComponent>
      </Paper>
    </PageLayout>
  );
};
