import { gql } from "@apollo/client";

export interface IPersonRoleSubjectInvitation {
  id: string;
  email: string;
  role: {
    id: string;
    locale: {
      id: string;
      name: string;
    } | null;
  };
  created: string;
  expired: string | null;
}

export interface IPersonRoleSubjectInvitationsData {
  allPersonRoleSubjectInvitations: IPersonRoleSubjectInvitation[];
}

export const ALL_PERSON_ROLE_SUBJECT_INVITATIONS = gql`
  query allPersonRoleSubjectInvitations {
    allPersonRoleSubjectInvitations {
      id
      email
      role {
        id
        locale {
          id
          name
        }
      }
      created
      expired
    }
  }
`;
