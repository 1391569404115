import { gql } from "@apollo/client";
export interface IDeleteClassicTour {
  id: string;
}
export interface IDeleteClassicTourData {
  deleteClassicTour: IDeleteClassicTour;
}
export interface IDeleteClassicTourVars {
  id: number;
}
export const DELETE_CLASSIC_TOUR = gql`
  mutation deleteClassicTour($id: ID!) {
    deleteClassicTour(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteClassicTourLocale {
  id: string;
  localeId: string;
}

export interface IDeleteClassicTourLocaleData {
  deleteClassicTourLocale: IDeleteClassicTourLocale;
}
export interface IDeleteClassicTourLocaleVars {
  id: number;
}

export const DELETE_CLASSIC_TOUR_LOCALE = gql`
  mutation deleteClassicTourLocale($id: ID!) {
    deleteClassicTourLocale(id: $id) {
      id
      localeId
    }
  }
`;
