import "./fonts/fonts.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
// import { StrictMode } from "react";

import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo";
import { BroadcastApp } from "./BroadcastApp";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    {/* <StrictMode> */}
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={6}>
          <CssBaseline>
            <ApolloProvider client={apolloClient}>
              <BroadcastApp />
            </ApolloProvider>
          </CssBaseline>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
    {/* </StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
