import { gql } from "@apollo/client";

export interface ILogoutCmsData {
  logoutCms: boolean;
}

export const LOGOUT = gql`
  mutation logoutCms {
    logoutCms
  }
`;
