import { gql } from "@apollo/client";
export interface IDeleteCheckpoint {
  id: string;
}
export interface IDeleteCheckpointData {
  deleteCheckpoint: IDeleteCheckpoint;
}
export interface IDeleteCheckpointVars {
  id: number;
}
export const DELETE_CHECKPOINT = gql`
  mutation deleteCheckpoint($id: ID!) {
    deleteCheckpoint(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteCheckpointLocale {
  id: string;
  localeId: string;
}

export interface IDeleteCheckpointLocaleData {
  deleteCheckpointLocale: IDeleteCheckpointLocale;
}
export interface IDeleteCheckpointLocaleVars {
  id: number;
}

export const DELETE_CHECKPOINT_LOCALE = gql`
  mutation deleteCheckpointLocale($id: ID!) {
    deleteCheckpointLocale(id: $id) {
      id
      localeId
    }
  }
`;
