import { gql } from "@apollo/client";

export interface IDeleteAdminVars {
  id: number;
}

export interface IDeleteAdmin {
  id: string;
}

export interface IDeleteAdminData {
  deleteAdmin: IDeleteAdmin;
}

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;
