import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useStyles } from "./ReceptionConfirmationDialog.styles";
import { joinClassNames } from "../../../../../utils";
import {
  EmailOutlined as EmailOutlinedIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import {
  IProduct,
  IReceptionPersonData,
  IReceptionPersonVars,
  ONE_RECEPTION_PERSON,
} from "../../../../../apollo/queries";
import { DataHandlerComponent } from "../../../../../components";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  price: string;
  discount: string | undefined;
  personId: string;
  products: IProduct[] | undefined;
  callMutation: () => void;
};

type TProps = DialogProps & TDialogProps;

export const ReceptionConfirmationDialog: FC<TProps> = (props) => {
  const {
    callMutation,
    products,
    personId,
    price,
    discount,
    open,
    onClose,
    ...rest
  } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [callOneReceptionPerson, { data, loading, error, refetch }] =
    useLazyQuery<IReceptionPersonData, IReceptionPersonVars>(
      ONE_RECEPTION_PERSON,
      {
        fetchPolicy: "network-only",
        onError: (err) => {
          enqueueSnackbar(err.message, { variant: "error" });
        },
      }
    );

  useEffect(() => {
    if (open && personId) {
      callOneReceptionPerson({
        variables: {
          personId: +personId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, callOneReceptionPerson]);

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          noDataMessage="There is no account associated with this email address."
          retryFunction={refetch}
          hasData={Boolean(data?.oneReceptionPerson?.id)}
          loading={loading}
          error={Boolean(error)}
        >
          {data?.oneReceptionPerson?.id ? (
            <>
              <Typography variant="h6">User</Typography>
              {!data.oneReceptionPerson.validated ? (
                <div className={classes.centerTextAndIcon}>
                  <WarningAmberOutlined color="warning" />
                  <Typography>
                    This user is not validated. User must validate his account
                    on his email address.
                  </Typography>
                </div>
              ) : null}
              <Divider className={classes.marginBottom} />
              <div className={classes.centerTextAndIcon}>
                <BadgeOutlinedIcon />
                <Typography>{data.oneReceptionPerson.id}</Typography>
              </div>
              <div className={classes.centerTextAndIcon}>
                <PersonOutlineIcon />
                <Typography>{data.oneReceptionPerson.name}</Typography>
              </div>
              <div
                className={joinClassNames([
                  classes.centerTextAndIcon,
                  classes.marginBottom,
                ])}
              >
                <EmailOutlinedIcon />
                <Typography>{data.oneReceptionPerson.email}</Typography>
              </div>

              <Typography variant="h6">
                You are unlocking the following content:
              </Typography>
              <Divider className={classes.marginBottom} />
              {products?.length
                ? products.map((item, i) => {
                    return (
                      <Typography key={item.id}>
                        {i + 1} -{" "}
                        {item.classicTour?.locale?.title ||
                          item.interactiveTour?.locale.title ||
                          `Unnamed product ${item.id}`}
                      </Typography>
                    );
                  })
                : null}
            </>
          ) : null}
        </DataHandlerComponent>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={callMutation}
          disabled={
            Boolean(error) || loading || !Boolean(data?.oneReceptionPerson?.id)
          }
          startIcon={<LockOpenOutlinedIcon />}
        >
          Unlock content (Total: {price}){discount ? ` (-${discount}%)` : ""}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
