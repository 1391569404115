import { gql } from "@apollo/client";

export interface ITourCategory {
  id: string;
  locale: {
    id: string;
    name: string;
  };
}

export interface ITourCategoriesData {
  allTourCategories: ITourCategory[];
}

export const ALL_TOUR_CATEGORIES = gql`
  query allTourCategories {
    allTourCategories {
      id
      locale(lang: "en-GB") {
        id
        name
      }
    }
  }
`;
