import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.lighter,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  mainFlag: {
    marginRight: 8,
    borderRadius: theme.shape.borderRadius,
  },
  flagsDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(4),
  },
  flag: {
    marginRight: 8,
    borderRadius: theme.shape.borderRadius,
    filter: "grayscale(1)",
    cursor: "pointer",
    "&:hover": {
      filter: "grayscale(0)",
      transform: "scale(1.1)",
    },
    transition: "all 0.3s ease-in-out",
  },

  selectedFlag: {
    filter: "grayscale(0)",
    transition: "all 0.3s ease-in-out",
  },
  deleteButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));
