import { gql } from "@apollo/client";

export interface IUpdatePersonPasswordVars {
  data: {
    password: string;
    passwordReset: string;
  };
}

export interface IUpdatePersonPassword {
  id: string;
}

export interface IUpdatePersonPasswordData {
  updatePersonPassword: IUpdatePersonPassword;
}

export const UPDATE_PERSON_PASSWORD = gql`
  mutation updatePersonPassword($data: PersonUpdatePasswordInput!) {
    updatePersonPassword(data: $data) {
      id
    }
  }
`;
