import { enqueueSnackbar } from "notistack";
import { UNKNOWN_ERROR_STRING } from "./constants";
// import { debugConsoleError } from "./debugConsole";

export const handleCatchError = (error: unknown) => {
  if (error instanceof NaratourError) {
    // debugConsoleError(error.message);
    enqueueSnackbar(error.message, { variant: "error" });
    return new Error(error.message);
  } else {
    // console.error(error);
    return new Error(UNKNOWN_ERROR_STRING);
  }
};

export class NaratourError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, NaratourError.prototype);
  }
}
